<template>
  <div class="about_us">
    <div class="about_us_main">
      <!-- 标题部分 -->
      <div class="title">
        <!-- <h1>公司简介</h1> -->
        <!-- <h4>公司简介</h4> -->
      </div>
      <!-- 主体部分 -->
      <div class="main">
        <!-- 左侧文字 -->
        <div class="main_text">
          <p class="duan">
            零碳在线：为公众了解低碳资讯、参与低碳经济与社会建设，推动低碳科技创新与产业化发展的专业性网络平台。
          </p>
          <p class="duan">
            平台积极配合国家政策方针、实行专业化运营，为新能源、节能减排、绿色环保等低碳领域的个人、企业及非营利性机构等提供国内外最新的低碳信息资讯以及专业的政策咨询、技术支撑、产品推广、项目投融资、碳交易与碳金融、人才与能力建设等专业服务。网站的设立同时也为国内外低碳领域的个人、企业及非营利性机构等架设了一座信息交换、资源共享的桥梁。
          </p>
          <p class="hang">
            用户可以得到一套完整的解决方案，包括但不限于以下功能：
          </p>
          <p class="hang">
            （1）打破信息壁垒：整合国内外低碳领域的新闻信息，促进知识共享和合作，提供全面及时的信息服务。
          </p>
          <p class="hang">
            （2）高质量的碳中和技术咨询：提供包括碳中和、碳盘查、碳交易、碳资产管理、碳金融、AIGC开发与应用的技术咨询与培训，帮助用户实现碳中和目标并推动可持续发展，提升碳中和技术的应用水平和管理能力。
          </p>
          <p class="hang">
            （3）人才梯队建设与培养：提供个性化的学习计划和内容，无论是学生还是在职人员，都可以通过智能直播数字人获取到最新的低碳行业知识和实践经验，提高自身素质和竞争力。
          </p>
          <p class="hang">
            （4）个性化的碳中和计划和服务：根据用户的需求和特点，定制个性化的碳中和计划和服务，大型企业、中小企业还是个人用户，都可以根据自身情况制定适合的策略，并实时监控和调整。
          </p>
          <p class="hang">
            （5）推动可持续发展和减少碳排放：致力于推动可持续发展和减少碳排放，通过促进碳中和技术的应用和推广，为构建低碳经济和绿色生态系统作出积极贡献。
          </p>
        </div>
        <!-- 右侧图片 -->
        <div class="main_img">
          <img src="@/assets/aboutUs-right.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUS"
};
</script>

<style scoped lang="less">
@duan: 60px;
@hang: 40px;
.about_us_main {
  padding: 0 @duan;
}
.about_us {
  background-color: #f9f9f9;
}
.title {
  margin-bottom: @duan;
  // font-size: 28px;
  // height: 24px;
}
.main {
  display: flex;
  justify-content: space-between;
  .main_text {
    width: 670px;
    font-size: 16px;
    font-weight: 400;
    .duan {
      margin-bottom: @duan;
    }
    .hang {
      margin-bottom: @hang;
    }
  }
  .main_img {
    img {
      width: 100%;
      height: 672px;
    }
  }
}
</style>
