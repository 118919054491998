var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),(this.dataList)?_c('div',{staticClass:"body"},[(_vm.currentTab == 'person')?_c('div',{staticClass:"main"},[_c('div',{staticClass:"main_item"},[_vm._m(2),_c('span',[_vm._v(_vm._s(_vm.dataList.name))])]),_vm._m(3),_c('div',{staticClass:"main_item"},[_vm._m(4),_c('span',[_vm._v(_vm._s(_vm.dataList.memberNo))])]),_c('div',{staticClass:"main_item"},[_vm._m(5),_c('span',[_vm._v(_vm._s(_vm.dataList.duringtime))])])]):_vm._e(),(_vm.currentTab == 'company')?_c('div',{staticClass:"main"},[_c('div',{staticClass:"main_item"},[_vm._m(6),_c('span',[_vm._v(_vm._s(_vm.dataList.name))])]),_vm._m(7),_c('div',{staticClass:"main_item"},[_vm._m(8),_c('span',[_vm._v(_vm._s(_vm.dataList.memberNo))])]),_c('div',{staticClass:"main_item"},[_vm._m(9),_c('span',[_vm._v(_vm._s(_vm.dataList.duringtime))])])]):_vm._e(),_c('div',{staticClass:"close_btn",on:{"click":function($event){return _vm.close()}}},[_vm._v(" 关闭 ")])]):_c('div',{staticClass:"no_body"},[(false)?_c('div',{staticClass:"main_tips"},[_c('p',[_vm._v("暂无结果")])]):_vm._e(),_vm._m(10)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav"},[_c('img',{attrs:{"src":require("@/assets/h5/logo_h5.png"),"alt":""}}),_c('h2',[_vm._v("零碳在线")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('h1',[_vm._v("上海市低碳科技与产业发展协会")]),_c('h1',[_vm._v("会员证书查询结果")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("姓")]),_c('span',[_vm._v("名：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_item"},[_c('div',{staticClass:"key"},[_c('span',[_vm._v("会员类型：")])]),_c('span',[_vm._v("个人会员证书")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("证书编号：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("起止时间：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("公司名称：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_item"},[_c('div',{staticClass:"key"},[_c('span',[_vm._v("会员类型：")])]),_c('span',[_vm._v("单位会员证书")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("证书编号：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("起止时间：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_tips"},[_c('p',[_vm._v("您输入的信息有误或您的")]),_c('p',[_vm._v("会员信息未更新/未注册")])])
}]

export { render, staticRenderFns }