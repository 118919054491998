<template>
  <div class="class_body_table">
    <div class="main_table">
      <img :src="this.nameObj[name].mainSrc" alt="" />
    </div>
    <div class="tab_list">
      <!-- <div
        class="tab_list_item"
        
      > -->
      <img
        v-for="(item, index) in nameObj[name].smSrcList"
        :key="index"
        :class="[nameObj[name].activeIndex == index ? 'active' : '']"
        :src="item"
        alt=""
        @click="changeImg(index)"
      />
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "finance"
    }
  },
  data() {
    return {
      nameObj: {
        finance: {
          mainSrc: require("@/assets/classBody/finance_table.png"),
          smSrcList: [
            require("@/assets/classBody/finance_table.png"),
            require("@/assets/classBody/psychology_table.png"),
            require("@/assets/classBody/art_table.png")
          ],
          //   激活的smSrc图
          activeIndex: 0
        },
        business: {
          mainSrc: "",
          smSrcList: [],
          //   激活的smSrc图
          activeIndex: 0
        },
        law: {
          mainSrc: "",
          smSrcList: [],
          //   激活的smSrc图
          activeIndex: 0
        },
        news: {
          mainSrc: "",
          smSrcList: [],
          //   激活的smSrc图
          activeIndex: 0
        },
        religion: {
          mainSrc: "",
          smSrcList: [],
          //   激活的smSrc图
          activeIndex: 0
        },
        statistics: {
          mainSrc: "",
          smSrcList: [],
          //   激活的smSrc图
          activeIndex: 0
        },
        psychology: {
          mainSrc: require("@/assets/classBody/psychology_table.png"),
          smSrcList: [
            require("@/assets/classBody/psychology_table.png"),
            require("@/assets/classBody/finance_table.png"),
            require("@/assets/classBody/art_table.png")
          ],
          //   激活的smSrc图
          activeIndex: 0
        },
        art: {
          mainSrc: require("@/assets/classBody/art_table.png"),
          smSrcList: [
            require("@/assets/classBody/art_table.png"),
            require("@/assets/classBody/psychology_table.png"),
            require("@/assets/classBody/finance_table.png")
          ],
          //   激活的smSrc图
          activeIndex: 0
        }
      }
    };
  },
  methods: {
    changeImg(index) {
      //获取当前的 类实例
      let classNow = this.nameObj[this.name];
      //切换选中的图片table
      classNow.activeIndex = index;

      //切换 显示的 主图table
      classNow.mainSrc = classNow.smSrcList[index];
      //用户切换后，对应类目的 isChanged 设置为true
      classNow.isChanged = true;
      //   console.log("切换了isChanged！:", classNow.isChanged);
    }
  },
  //   mounted() {
  //     console.log("mmmm");
  //   }
  updated() {
    //   this.activeIndex=0;
  }
};
</script>

<style lang="less" scoped>
.class_body_table {
}
.main_table {
  text-align: center;
}
.tab_list {
  //   display: flex;
  text-align: center;
  .tab_list_item {
  }
  img {
    &:nth-child(-n + 2) {
      margin-right: 30px;
    }
    width: 220px;
    height: 150px;
    box-sizing: border-box;
  }
  .active {
    border: 2px solid #333333;
  }
}
</style>