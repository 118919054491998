<template>
  <div class="class_detail">
    <div class="class_detail_main">
      <div class="main_title">{{ mainTitle }}</div>
      <div class="main_time">
        <i class="el-icon-caret-right"></i>
        <span class="time">{{
          "课程时间：" + beginDate + " - " + endDate
        }}</span>
      </div>
      <!-- 富文本 -->
      <div class="main_rich_content" v-html="richContent"></div>
      <!-- 课程二维码 -->
      <div class="main_class_code">
        <div class="class_code_title">
          <i class="el-icon-caret-right"></i>
          <span>课程二维码如下（请用微信扫码）：</span>
        </div>
        <img class="class_code_img" :src="qRCodeURL" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      richContent: "",
      mainTitle: "",
      beginDate: "",
      endDate: "",
      qRCodeURL: ""
    };
  },
  methods: {
    // 请求课程详情数据
    initDetailPage(dataId) {
      this.$http
        .post("applet/training/getLessonInfo", {
          lessonId: dataId
        })
        .then(res => {
          console.log("getLessonInfo", res);
          //courseName,beginDate,endDate

          let dataObj = res.result;
          //   标题
          this.mainTitle = dataObj.courseName;
          //时间待处理
          this.beginDate = this.formatDate(dataObj.beginDate);
          console.log(this.beginDate);
          this.endDate = this.formatDate(dataObj.endDate);
          //   富文本
          this.richContent = dataObj.content;
        });
    },
    getQRCode(dataId) {
      let data =
        "/curriculum-activity/curriculum-page/apply-page?id=" + dataId + "&v4";
      this.$http
        .post("applet/wxAuth/getWxaCodeByLongLinkV4", { longLink: data })
        .then(res => {
          //result.wxaCodeUrl
          console.log("getQRCode", res);
          this.qRCodeURL = res.result.wxaCodeUrl;
          console.log("res.wxaCodeUr:", res.result.wxaCodeUrl);
          console.log("qRCodeURL:", this.qRCodeURL);
        });
    },
    // 日期格式化
    formatDate(str) {
      const date = new Date(str); // 将字符串转化为 Date 对象
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hour = String(date.getHours()).padStart(2, "0");
      const minute = String(date.getMinutes()).padStart(2, "0");
      return `${year}年${month}月${day}日 ${hour}:${minute}`;
    }
  },
  created() {
    let dataId = this.$route.query.dataId;
    console.log(dataId);
    this.initDetailPage(dataId);
    this.getQRCode(dataId);
  }
};
</script>

<style lang="less" scoped>
.class_detail_main {
  padding: 60px;
  min-height: 450px;
  background-color: #f9f9f9;
  .main_title {
    font-weight: 900;
    font-size: 24px;
    margin-bottom: 30px;
  }
  .main_time {
    i {
      font-size: 20px;
      margin-left: -5px;
      margin-right: 5px;
    }
    span {
    }
    margin-bottom: 30px;
    // border-bottom: 1px solid #fff;
  }
  // 富文本模块
  .main_rich_content {
    margin-bottom: 30px;
  }
  // 二维码模块
  .main_class_code {
    .class_code_title {
      margin-bottom: 30px;
      i {
        font-size: 20px;
        margin-left: -5px;
        margin-right: 5px;
      }
      span {
      }
    }
    .class_code_img {
      width: 100px;
      height: 100px;
    }
  }
}
</style>
