<template>
  <div class="container">
    <div class="nav">
      <img src="@/assets/h5/logo_h5.png" alt="" />
      <h2>零碳在线</h2>
    </div>

    <div class="header">
      <h1>上海市低碳科技与产业发展协会</h1>
      <h1>会员证书查询</h1>
    </div>

    <div class="body">
      <!-- tab行 -->
      <div class="tab_list">
        <div
          class="tab"
          @click="changeTab()"
          :class="[currentTab == 'person' ? 'active' : '']"
        >
          个人
        </div>
        <div
          class="tab"
          @click="changeTab()"
          :class="[currentTab == 'company' ? 'active' : '']"
        >
          单位
        </div>
      </div>
      <!-- tab表主体---个人 -->
      <div v-if="currentTab == 'person'" class="main">
        <!-- 验证方式1 -->
        <div class="validate_one">
          <!-- 左上角 tips：验证方式1： -->
          <div class="tip">验证方式1：</div>
          <!-- 表单1 -->
          <div class="form1">
            <input
              v-model="centNo"
              :disabled="name.length != 0 || idCard.length != 0"
              type="text"
              placeholder="证书编号"
            />
          </div>
        </div>
        <!-- 验证方式2 -->
        <div class="validate_two">
          <!-- 左上角tips：验证方式2： -->
          <div class="tip">验证方式2：</div>
          <!-- 表单2 -->
          <div class="form2">
            <input
              :disabled="centNo.length != 0"
              v-model="name"
              type="text"
              placeholder="姓名"
            />
            <input
              :disabled="centNo.length != 0"
              v-model="idCard"
              type="text"
              placeholder="身份证号码"
              @blur="idCardBlur"
            />
          </div>
          <p class="validErr" v-show="showIdCardErr">身份证号码格式错误</p>
        </div>
      </div>
      <!-- tab表主体---企业 -->
      <div v-else class="main">
        <!-- 验证方式1 -->
        <div class="validate_one">
          <!-- 左上角 tips：验证方式1： -->
          <div class="tip">验证方式1：</div>
          <!-- 表单1 -->
          <div class="form1">
            <input
              v-model="comCentNo"
              :disabled="comName.length != 0 || comCreditCode.length != 0"
              type="text"
              placeholder="证书编号"
            />
          </div>
        </div>
        <!-- 验证方式2 -->
        <div class="validate_two">
          <!-- 左上角tips：验证方式2： -->
          <div class="tip">验证方式2：</div>
          <!-- 表单2 -->
          <div class="form2">
            <input
              :disabled="comCentNo.length != 0"
              v-model="comName"
              type="text"
              placeholder="公司名称"
            />
            <input
              :disabled="comCentNo.length != 0"
              v-model="comCreditCode"
              type="text"
              placeholder="信用代码"
            />
          </div>
        </div>
      </div>

      <!-- 查询按钮 -->
      <div class="search_btn" @click="search()">查询</div>
    </div>
  </div>
</template>

<script>
import {
  searchPersonCentNoResult,
  searchPersonIdResult,
  searchComCentNoResult,
  searchComIdResult
} from "@/api/common/h5/search.js";
// 测试
import axios from "axios";
export default {
  data() {
    return {
      currentTab: "person", //值为 person/company  代表当前的tab页；
      dataList: {}, //存储 请求获取的数据
      //input绑定的数据
      centNo: "", //个人 证书编号
      name: "", //个人 姓名
      idCard: "", //个人 身份证号

      comCentNo: "", //企业证书编号
      comName: "", //企业 名称
      comCreditCode: "", //企业 信用代码

      showIdCardErr: false //验证 身份证->控制错误信息的显示与隐藏
    };
  },
  methods: {
    search() {
      // 测试内容：
      // console.log(this.centNo.length == false);
      // console.log(this.idCard.length);
      //发送请求：
      // 先判断当前是 个人 还是 企业
      if (this.showIdCardErr == true) {
        alert("请检查身份证号码是否正确!");
        return;
      }
      if (this.currentTab == "person") {
        // 为个人时，调用个人2个接口;查询 证书编号  或者 姓名[身份证号]
        if (this.centNo.length != 0) {
          console.log("个人证书编号接口调用前参数：", this.centNo);
          searchPersonCentNoResult({
            certifiNo: this.centNo
          })
            .then(res => {
              console.log("请求 个人 证书编号查询成功：", res);
              // console.log("纯数据：", res.result[0]);
              this.dataList = res.result[0];
              // console.log("datalist:", this.dataList);
              this.toResult();
            })
            .catch(err => {
              console.log("请求 个人 证书编号接口失败：", err);
            });
        } else if (this.name.length != 0 || this.idCard.length != 0) {
          let param = {
            name: this.name,
            idcard: this.idCard
          };
          console.log("个人身份证接口调用前参数：", param);
          searchPersonIdResult(param)
            .then(res => {
              console.log("请求 个人身份证接口成功：", res);
              this.dataList = res.result[0];
              this.toResult();
            })
            .catch(err => {
              console.log("请求 个人身份证接口失败：", err);
            });
        } else {
          alert("请填写信息再进行查询！");
          return;
        }
      } else if (this.currentTab == "company") {
        // 为企业时，调用企业2个接口;查询 证书编号 或者 企业名称/信用代码
        if (this.comCentNo.length != 0) {
          console.log("企业证书编号接口调用前参数：", this.comCentNo);
          searchComCentNoResult({
            certifiNo: this.comCentNo
          })
            .then(res => {
              console.log("请求 企业 证书编号查询成功：", res);
              this.dataList = res.result[0];
              this.toResult();
            })
            .catch(err => {
              console.log("请求 企业 证书编号接口失败：", err);
            });
        } else if (this.comName.length != 0 || this.comCreditCode.length != 0) {
          let param = {
            name: this.comName,
            idCard: this.comCreditCode
          };
          console.log("企业信用代码接口调用前参数：", param);
          searchComIdResult(param)
            .then(res => {
              console.log("请求 企业 信用代码 接口成功：", res);
              this.dataList = res.result[0];
              this.toResult();
            })
            .catch(err => {
              console.log("请求 企业 信用代码 证接口失败：", err);
            });
        } else {
          alert("请填写信息再进行查询！");
          return;
        }
      }

      //请求完成后跳转
      // this.$nextTick(() => {

      // });
    },
    // 带参跳转到结果页
    toResult() {
      this.$router.push({
        path: "/h5/searchResult",
        query: {
          currentTab: this.currentTab,
          dataList: this.dataList
        }
      });
      //跳转后初始化：
      this.dataList = {};
    },
    changeTab() {
      if (this.currentTab == "person") {
        this.currentTab = "company";
      } else {
        this.currentTab = "person";
      }
      console.log("切换了tab页，当前tab页为：", this.currentTab);
    },
    // 身份证号码验证
    idCardBlur() {
      let rep = /^\d{15}|\d{18}$/;
      if (rep.test(this.idCard) || this.idCard.length == 0) {
        this.showIdCardErr = false;
      } else {
        this.showIdCardErr = true;
      }
    }
  }
};
</script>

<style scoped lang="less">
@padding_hor: 20px;
@padding_ver: 15px;
@body_radius: 8px;
// 所有样式/3
html {
  padding: 0;
  margin: 0;
}
.active {
  border-bottom: 3px solid #22a3ffaf;
}
.container {
  text-align: center;
  //   padding: 20px;
}
.nav {
  padding: 10px 20px 20px;
  display: flex;
  align-items: center;
  img {
    display: block;
    width: 3rem;
    height: 2rem;
    margin-right: 20px;
    // vertical-align: middle;
  }
  h2 {
    display: block;
    font-size: 16px;
    // vertical-align: middle;
  }
}
.header {
  padding: 10px 45px;
  background-color: #eaf4ff;
  h1 {
    font-size: 18px;
  }
}
.body {
  padding: @padding_ver @padding_hor;
  .tab_list {
    display: flex;
    .tab {
      //   border-bottom: 3px solid #22a3ffaf;
      margin-bottom: -3px;
      margin-right: 25px;
    }
  }
  .main {
    .validate_one,
    .validate_two {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      input {
        height: 30px;
        width: 260px;
        border-radius: @body_radius;
        border: none;
        padding: 10px 20px;
      }
      .tip {
        position: absolute;
        left: 0;
        top: 0;
        width: 80px;
        height: 20px;
        border: 1px solid #fff;
        background-color: #a9d1fc;
        border-radius: 8px 0 8px 0;
        overflow: hidden;

        padding-left: 8px;
        color: #fff;
        font-size: 12px;
        line-height: 22px;
      }
      .validErr {
        position: absolute;
        bottom: 10px;
        font-size: 14px;
        color: red;
      }
    }
    .validate_one {
      width: 100%;
      height: 111px;
      background-color: #99c7ff;
      margin: 30px 0;
      border-radius: @body_radius;
      .form1 {
        input {
          margin-top: 20px;
        }
      }
    }
    .validate_two {
      width: 100%;
      height: 184px;
      background-color: #cae3ff;
      border-radius: @body_radius;
      margin-bottom: 30px;
      .form2 {
        input {
          &:nth-child(1) {
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .search_btn {
    width: 100%;
    height: 40px;
    background-color: #007aff;
    border-radius: @body_radius;
    font-size: 14px;
    color: #fff;
    line-height: 40px;
  }
}
</style>