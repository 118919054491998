<template>
  <div class="activity_detail">
    <div class="activity_detail_main">
      <!-- 添加返回到顶层和返回上一页的按钮 -->
      <div class="main_header">
        <button class="back_prev_btn" @click="backToPrev">返回活动列表</button>
      </div>
      <div class="main_title">{{ mainTitle }}</div>
      <div class="main_time">
        <i class="el-icon-caret-right"></i>
        <span class="time">{{ "活动时间：" + beginDate + " - " + endDate }}</span>
      </div>
      <!-- 富文本 -->
      <div class="main_rich_content" v-html="richContent"></div>
      <!-- 课程二维码 -->
      <div class="main_activity_code">
        <div class="activity_code_title">
          <i class="el-icon-caret-right"></i>
          <span>活动二维码如下（请用微信扫码）：</span>
        </div>
        <img class="activity_code_img" :src="qRCodeURL" alt="" />
      </div>
    </div>
    <el-backtop target=".activity_detail"></el-backtop>
  </div>
</template>

<script>
export default {
  data() {
    return {
      richContent: "",
      mainTitle: "",
      beginDate: "",
      endDate: "",
      qRCodeURL: "https://qiniu.chinardr.net/lingtanzaixianCode.png"
    };
  },
  methods: {
    // 返回上一页
    backToPrev() {
      this.$router.go(-1);
    },
    // 请求活动详情数据
    initActivtyDetail(dataId) {
      //6411930f2874e13e98989626
      //返回对象：res.result.    ->mainTitle:theme;beginDate:startDate;endDate:endDate;
      //richContent:content
      this.$http.post("applet/activity/get", { id: dataId }).then(res => {
        console.log("活动详情返回对象：", res);
        const dataObj = res.result;
        this.mainTitle = dataObj.theme;
        //时间待处理
        this.beginDate = this.formatDate(dataObj.startDate);
        console.log("活动详情beginDate:", this.formatDate(dataObj.beginDate));
        this.endDate = this.formatDate(dataObj.endDate);
        //   富文本
        this.richContent = dataObj.content;
      });
    },
    getQRCode(dataId) {
      let data =
        "/curriculum-activity/activity/activity-detail?actId=" + dataId + "&v4";
      this.$http
        .post("applet/wxAuth/getWxaCodeByLongLinkV4", { longLink: data })
        .then(res => {
          //result.wxaCodeUrl
          console.log("getQRCode", res);
          this.qRCodeURL = res.result.wxaCodeUrl;
          console.log("res.wxaCodeUr:", res.result.wxaCodeUrl);
          console.log("qRCodeURL:", this.qRCodeURL);
        });
    },
    // 日期格式化
    formatDate(str) {
      const date = new Date(str); // 将字符串转化为 Date 对象
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hour = String(date.getHours()).padStart(2, "0");
      const minute = String(date.getMinutes()).padStart(2, "0");
      return `${year}年${month}月${day}日 ${hour}:${minute}`;
    }
    //test
    // test() {
    //   let data = "/common-page/me-page/lowCarbonAssociation";
    //   this.$http
    //     .post("applet/wxAuth/getWxaCodeByLongLinkV4", { longLink: data })
    //     .then(res => {
    //       console.log("test:", res);
    //     });
    // }
  },
  created() {
    let dataId = this.$route.query.dataId;
    console.log(dataId);
    this.initActivtyDetail(dataId);
    this.getQRCode(dataId);

    // this.test();
  }
};
</script>

<style lang="less" scoped>
.activity_detail_main {
  .main_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .back_prev_btn {
      padding: 8px 16px;
      background-color: #409eff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  padding: 60px;
  min-height: 450px;
  background-color: #f9f9f9;
  .main_title {
    font-weight: 900;
    font-size: 24px;
    margin-bottom: 30px;
  }
  .main_time {
    i {
      font-size: 20px;
      margin-left: -5px;
      margin-right: 5px;
    }
    span {
    }
    margin-bottom: 30px;
    // border-bottom: 1px solid #fff;
  }
  // 富文本模块
  .main_rich_content {
    margin-bottom: 30px;
  }
  // 二维码模块
  .main_activity_code {
    .activity_code_title {
      margin-bottom: 30px;
      i {
        font-size: 20px;
        margin-left: -5px;
        margin-right: 5px;
      }
      span {
      }
    }
    .activity_code_img {
      width: 100px;
      height: 100px;
    }
  }
}
</style>
