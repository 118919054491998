<template>
  <div id="app2" v-title data-title="零碳在线H5">
    <div class="subapp_container">
      <div class="contain" style="flex">
        <!-- 这里的router-view调用H5组件 -->
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "APP2",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
  
  <style scoped>
/* 样式表 */
</style>
  