<template>
  <div class="stage">
    <div class="tips">上海市低碳科技与产业发展协会入会流程</div>
    <div class="picture">
      <el-image :src="require('../assets/vipstage.png') " fit="fit"></el-image>
    </div>
    <div style="text-align: left;">
      <div class="tipmessage">个人会员费: 100元（人民币）/年</div>
      <div class="tipmessage">单位会员费: 1000元（人民币）/年</div>
      <!--        <div class="tipmessage"><span style="display: inline-block; width: 88px;"></span>中型3000元（人民币）/年</div>-->
      <!--        <div class="tipmessage"><span style="display: inline-block; width: 88px;"></span>大型10000元（人民币）/年</div>-->
      <div class="tipmessage">小型企业或公益组织可根据具体情况中请会员费减免</div>
      <div class="tipmessage">理事单位:5万元/年</div>
      <div class="tipmessage">会长、副会长单位:20万 元 /年</div>
      <div class="tipmessage">其他相关资料：</div>
      <div class="tipmessage">个人：身份证复印件</div>
      <div class="tipmessage">单位：单位营业执照复印件、单位简介</div>
      <div class="tips">申请成为会员</div>
      <div class="tipmessage">欢迎您申请加入中国首个低碳科技与产业发展协会“上海市低碳科技与产业发展协会”，请登录：</div>
      <div class="tipmessage">协会微博: http://blog.sina.com.cn/u/2593681634</div>
      <div class="tipmessage">官方网站: http://www.chinaver.org.cn</div>
      <div class="tipmessage">零碳在线小程序：</div>
      <div class="qrcodes">
        <div>
          <el-image :src="qrCodes" style="height: 200px;width: 200px;"></el-image>
          <div style="font-size: 16px;font-weight: 400;width: 200px; text-align: center; margin-top: 10px;">
            扫码注册会员
          </div>
        </div>
        <div style="margin-left: 20px;">
          <el-image :src="require('@/assets/qRcode.jpg')" style="height: 200px;width: 200px;"></el-image>
          <div style="font-size: 16px;font-weight: 400;width: 200px; text-align: center; margin-top: 10px;">
            扫码关注公众号
          </div>
        </div>
      </div>
      <div class="tipmessage">前往小程序或公众号下载并填写会员申请表，发送至:</div>
      <div class="tipmessage">邮箱:shdtxh@chinaver.net(主题：申请成为会员)</div>
      <div class="tipmessage">
        请下载并填写会员表单，将填写好的会员申请表原件寄回协会秘书处，我们将尽快与您取得联系，也可直接通过下方联系方式，主动联系我们；
      </div>
      <div class="tipmessage">地 址：上海市黄浦区六合路58号新一百大厦17层；收件人：蒋老师 18916759591（同微信）</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoGin",
  data() {
    return {
      qrCodes: '',
    };
  },
  created() {
    this.getQRCode();
  },
  methods: {
    getQRCode() {
      let data = "/common-page/me-page/lowCarbonAssociation"
      this.$http
          .post("applet/wxAuth/getWxaCodeByLongLinkV4", {longLink: data})
          .then(res => {
            this.qrCodes = res.result.wxaCodeUrl;
          });
    },
  }
};
</script>

<style>
.stage {
  padding: 60px 30px;
  margin: 0 auto;
  /* justify-content: center; */
  text-align: center;
  width: 825px;
}

.tips {
  font-size: 28px;
  font-weight: 400;
  color: #333333;
}

.picture {
  margin: 30px 0;
}

.tipmessage {
  font-style: 18px;
  font-weight: 400;
  color: #333333;
  margin: 20px 0;
}

.qrcodes {
  display: flex;
  flex-direction: row;
  /* align-items: flex-end;  */
}
</style>
