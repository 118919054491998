// H5相关接口 YSY  2023.07.03
import http from '@/api/common/index.js'

const api_version = 'v4/'

/**
 * 按 个人 证书编号查询
 */
export function searchPersonCentNoResult(param) {
    return http({
        url: api_version + 'carbonMemberPersonal/selectPersonInfo',
        method: 'post',
        data: param
    })
}
/**
 * 按 个人 姓名/身份证号 查询
 */
export function searchPersonIdResult(param) {
    return http({
        url: api_version + 'carbonMemberPersonal/idCard',
        method: 'post',
        data: param
    })
}



/**
 * 按 企业 证书编号 查询
 */
export function searchComCentNoResult(param) {
    return http({
        url: api_version + 'carbonMemberCompany/selectPersonInfo',
        method: 'post',
        data: param
    })
}



/**
 * 按 企业 名称/信用代码 查询
 */
export function searchComIdResult(param) {
    return http({
        url: api_version + 'carbonMemberCompany/idCard',
        method: 'post',
        data: param
    })
}
// export function searchCentNoResult(param) {
//     return http({
//       url: 'carbonMemberCompany/selectPersonInfo',
//       method: 'get',
//       params: param
//     })
//   }