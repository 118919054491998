<template>
  <div id="app" v-title data-title="零碳在线">
    <div class="app_container">
      <div class="contain" style="flex">
        <!-- 这里的router-view调用Home组件 -->
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "APP",
  components: {},
  data() {
    return {};
  },
  mounted() { },
  methods: {}
};
</script>

<style scoped>
/* 样式表 */
</style>
