// 封装axios YSY 2023.07.03
import axios from 'axios';

// 创建一个 Axios 实例
const http = axios.create({
    baseURL: 'https://wxapp.chinardr.net/', // API 的基础URL
    timeout: 60000, // 请求超时时间
    headers: {
        'Content-Type': 'application/json', // 请求头中的 Content-Type
    }
});



//以下为封装的请求->
// 封装 GET 请求
export function get(url, params) {
    return http.get(url, {
        params
    });
}

// 封装 POST 请求
export function post(url, data) {
    return http.post(url, data);
}

// 封装 PUT 请求
export function put(url, data) {
    return http.put(url, data);
}

// 封装 DELETE 请求
export function del(url) {
    return http.delete(url);
}


//拦截器暂时不用
// 请求拦截器
http.interceptors.request.use(
    (config) => {
        // 在发送请求之前做些什么
        // 比如对请求参数进行处理、添加公共参数等
        return config;
    },
    (error) => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// // 响应拦截器
http.interceptors.response.use(
    (response) => {
        // 对响应数据做些什么
        // 比如统一处理错误码、格式化数据等
        return response.data;
    },
    (error) => {
        // 对响应错误做些什么
        return Promise.reject(error);
    }
);

export default http;