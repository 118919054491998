 <template>
  <div class="activity_intro">
    <div class="activity_list">
      <div
        v-for="(item, index) in initActiveList"
        :key="index"
        class="activity_item"
        @click="goToDetail(item._id)"
      >
        <div class="item_pic">
          <img
              style="height: 15vh;"
            :src="item.bannerPicPath"
            alt=""
          />
        </div>
        <div class="item_text">
          <p>
            {{ item.theme }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //用于存储 初始化请求获得的 活动列表数据
      initActiveList: []
    };
  },
  created() {
    // 发送 活动页面初始化请求
    this.initActivityPage();
    //测试调用 活动详情页接口
    // this.initActivtyDetail();
  },
  methods: {
    initActivityPage() {
      this.$http
        .post("applet/activity/getList")
        .then(res => {
          //res.result.data是个数组对象（结构：[{01},{02}]
          //将请求的数据 存储到 data节点
          this.initActiveList = res.result.data;
          console.log("活动请求返回参数：", this.initActiveList);
        })
        .catch(err => {
          console.log("初始化活动错误信息：", err);
        });
    },

    goToDetail(activityId) {
      this.$router.push({
        path: "/home/activitydetail",
        query: { dataId: activityId }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@imgWidth: 306px;
@imgHeight: 175px;
.activity_intro {
  padding: 60px;
  background-color: #f9f9f9;
}
.activity_list {
  display: flex;
  flex-wrap: wrap;
  .activity_item {
    margin-bottom: 30px;
    //&:not(:nth-child(4n)) {
      margin-right: 18px;
    //}
    .item_pic {
      img {
        // vertical-align: center;
        width: @imgWidth;
        height: 100%;
        vertical-align: bottom;
      }
    }
    .item_text {
      width: @imgWidth;
      height: 84px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      background-color: #fff;
      box-sizing: border-box;
      text-align: center;

      p {
        margin: 0;
        padding: 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
</style>
