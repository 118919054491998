<template>
  <div class="class_body_text">
    <div class="text_list">
      <p
        v-for="(item, index) in nameObj[name]"
        :key="index"
        class="text_list_item"
      >
        {{ item }}
      </p>
      <p>研究方向如下：</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "finance"
    }
  },
  data() {
    return {
      nameObj: {
        finance: [
          "（2017年教育部学位与研究生教育发展中心公布全国第四轮学科评估结果，理论经济学和应用经济学科门类评估结果均为A+）",
          "2017 年教育部公布国家第四轮一级学科评估结果，中国人民大学理论经济学、应用经济学等9 个一级学科排名全国第一，其中以金融学、财政学为骨干学科的应用经济学已连续4 次排名全国之首，2017年中国人民大学成功入选一流大学建设高校名单，同时“应用经济学”和“理论经济学”成功首批入选一流学科建设名单。经济学院是国家经济学基础人才培养基地、教育部人文社会科学重点研究基地、国家“统筹支持一流大学和一流学科建设”项目、国家“2011”中国特色社会主义经济建设协同创新中心协同单位和全国中国特色社会主义政治经济学研究中心。"
        ],
        business: [
          "（2017年教育部学位与研究生教育发展中心公布全国第四轮学科评估结果，工商管理学科门类评估结果为A+）",
          "劳动人事学院是“中国人力资源管理第一品牌”，中国人力资源管理的发源地，人力资源管理学术领域最早的开创者，人力资源管理思想的引领者，中国人力资源管理实践的推动者。作为全国最早唯一一所拥有本硕博完整人力资源管理专业教育体系的学院，劳动人事学院各专业所属的一级学科在全国第四轮学科评估中评估结果均为A+。是我国人力资源管理、劳动经济学、社会保障和劳动关系人才的主要培养基地。",
          "中国人民大学商学院是我国最早开办管理教育的机构，是新中国工商管理教育的奠基者。商学院拥有工商管理国家重点一级学科以及企业管理、产业经济学和会计学3个国家重点二级学科，学科综合实力在国内高校中名列前茅。2012年，中国人民大学工商管理学科（商学院）在教育部第三轮一级学科评估中名列全国第一；2017年，在第四轮一级学科评估中获评A+级（最高等级）。2017年，中国人民大学工商管理学科（商学院）入选教育部“世界一流学科”建设名单。"
        ],
        law: [
          "（中国法学教育的“中国母机”和“法学家的摇篮”，教育部学科评估，法学学科A+）",
          "中国人民大学法学院是中国人民大学建校八大系之一，成立于 1950 年，是新中国诞生后创立的第一所正规的高等法学教育机构，被誉为中国法学教育的“工作母机”和“法学家的摇篮”，成为引领法学教育的重镇、凝聚国内优秀法律人才的平台和沟通中外法学交流的窗口。在教育部2004、2009、2012、2017 年四次正式公布的全国一级学科评估排名中，中国人民大学法学院在全国法学学科中均排名第一，并开始跻身于世界一流法学院行列。同时，中国人民大学和法学学科分别进入了国家世界一流大学和一流学科（简称“双一流”）建设名单。"
        ],
        news: [
          "（新中国记者摇篮、新闻传播教育工作母机、新闻传播教育改革创新引领者，教育部学科评估新闻传播学科蝉联第一或评为“A+”）",
          "中国人民大学新闻学院是新中国记者摇篮、马克思主义新闻学研究重镇、新闻传播教育工作母机、新闻传播教育改革创新引领者、全球新闻教育交流合作重要平台。学院首批获得新闻传播学一级学科学位授予权，拥有新闻学、传播学两个国家重点学科和国家级实验教学示范中心，仅有的同时拥有新闻学、传播学两个一级重点学科的学院之一。在教育部开展的四次全国一级学科评估中，中国人民大学新闻传播学科蝉联第一或评为“A+”。2017 年，中国人民大学新闻传播学科进入国家“双一流”学科建设序列。下设 11 个研究所，分别为新闻与传播研究所、舆论研究所、传播媒介管理研究所、公共传播研究所、新媒体研究所、新闻伦理与传媒法律研究所、现代广告研究所、网络舆情研究所、未来传播研究所、媒体技术研究所和媒介心理研究所。主要研究领域有：新闻学、传播学、广播电视学及媒介经济学等。"
        ],
        religion: [
          "（中国哲学教育的工作母机，具有国际影响力的哲学教育、研究机构，教育部学科评估名列前茅） ",
          "中国人民大学哲学院是国内哲学教学、科研、咨政和培养高级人才的重镇，是具有国际影响力的哲学教育、研究机构，被评为首批国家重点学科、首批国家重点一级学科、首批一级学科授权点，被称作“中国哲学教育的工作母机”。在 QS 世界大学一流哲学专业 2019 年和2020年排行榜上，中国人民大学哲学专业名列32位，连续三年蝉联中国大陆高校排名最高的哲学专业。此外，宗教学专业名列50—100 名。迄今在中国哲学范畴与专题、儒家哲学与儒释道关系，宋明理学，近现代哲学，传统哲学文献，中国哲学与文化，中国哲学与东方哲学比较等研究领域中居于国内外领先地位。",
          "宗教学专业为国家重点学科、教育部重点研究基地，佛教、基督教和宗教社会学研究优势突出。从2006年开始，中国人民大学受中央统战部和教育部的委托，在中央统战部二局和国家宗教局的具体指导下，以学校哲学院为基本办学力量，承接了“爱国宗教界人士”的定期培训任务，培训对象主要是全国五大宗教——佛教、道教、伊斯兰教、天主教、基督教之全国性和各省市自治区宗教团体的负责人。该研修班之设，旨在对全国五大宗教的中青年爱国领袖进行文化知识和专业素质的系统教育，旨在使他们成为“政治上靠得住、学识上有造诣、品德上能服众，能够带领广大信教群众拥护党的领导和社会主义制度、热爱祖国、维护祖国统一、促进社会和谐的宗教界代表人士”。"
        ],
        statistics: [
          "（我国计算机学科数据库领域教学和研究的开创者，建有第一个经济科学实验室，数据库及大数据研究团队在国内学术界一直处于领先地位；统计学学科评估全国排名第一）",
          "人大信息学院是国内率先将“信息”一词命名为专业名称的学院，是我国计算机学科数据库领域教学和研究的开创者，第一批数量经济学科重点单位，强调学科交叉融合应用，逐步形成了以“数据为中心”的产学研用一体化为特色的人才高地，建有第一个经济科学实验室，数据库及大数据研究团队在国内学术界一直处于领先地位。人大高瓴人工智能学院于2019 年 4 月正式成立，以推动人工智能基础理论和技术研究，服务国家人工智能发展战略；促进人文社科与人工智能的深度融合，联合各界设立新型交叉研究中心和实验室；与全球知名人工智能企业合作打造专项人才培养计划，鼓励创新和产业化，促进凝聚广泛共识的人工智能全球对话为人才培养目标。",
          "中国人民大学统计学院不断拓宽统计教学和研究领域，成为统计学全国重点学科，在2007年教育部统计学科评估中排名全国第一，2012年教育部统计学一级学科评估中排名全国第一，2017年教育部统计学一级学科评估中排名全国第一。统计学院注重统计理论与应用的结合，设有国家政府统计研究院、北美精算协会（SOA）考试中心、教育部人文社会科学重点研究基地“应用统计科学研究中心”与“全国应用统计专业学位研究生教育指导委员会”。目前，学院拥有统计学一级学科博士点和博士后流动站，拥有经济统计学和风险管理与精算学两个二级学科博士点，拥有预防医学与公共卫生一级学科硕士授权点，统计学、概率论与数理统计、风险管理与精算学、流行病与卫生统计学四个学术型硕士点，应用统计学专业学位硕士点，统计学、经济统计学、应用统计学（风险管理与精算）三个本科专业，是全国拥有理学、经济学、医学三大门类统计学专业最齐全的统计学院。"
        ],
        art: [
          "（办学水平居全国高校前列，借助人大深厚的人文环境和学科沉淀，以跨学科的优势加强艺术、文学等理论的广度和深度，形成理论与实践相结合的办学特色）",
          "中国人民大学文学院已经成为学科体系完备、专业实力雄厚的语言文学研究和一流人才培养重镇，包括文艺学、语言学及应用语言学、汉语言文字学、中国古典文献学、中国古代文学、中国现当代文学、比较文学与世界文学、电影学、戏剧戏曲学、汉语国际教育10 个二级学科，另外还包括2 个教育部自设二级学科古典学、创造性写作。文学院先后被批准为“国家文科基础学科（中国语言文学）人才培养和科学研究基地”“国家对外汉语教学基地”。在教育部第四轮学科评估中，文学院中国语言文学跻身A 类，办学水平位居全国高校前列。",
          "中国人民大学艺术学院前身是徐悲鸿艺术学院，艺术学系构建优势突出的艺术理论教育与研究的新方向，借助人民大学深厚的人文环境和学科积淀，以跨学科的优势加强艺术理论的广度和深度，并以艺术理论为核心，形成理论与实践相结合的办学特色。"
        ],
        psychology: [
          "中国人民大学是国内较早开展心理学学科建设的高校之一，在上世纪50年代就培养了新中国第一批心理学专业研究生；后于1985年建立了社会心理学教研室，招收社会心理学方向的硕士和博士研究生；2004年设立社会心理学的硕士和博士点；2009年6月2日，中国人民大学为进一步提升学科竞争力，完善“主干的文科、精干的理工科”的学科布局，在理学院下成立心理系，同年开始招收应用心理学专业的本科生。",
          "2011年6月，心理学系获批心理学一级学科硕士学位授予权，下设基础心理学和应用心理学两个二级学科方向； 2014年在中国语言文学一级学科下自设心理语言学硕士点和博士点；2017年心理学系又获批应用心理专业硕士授予权，2020年实施第一批招生。",
          "建系十年来，心理学系发展迅速，在2020年QS排名中，人大心理学科位列全国并列第五。2021年3月，人大心理学科进入ESI前1%，成为人大第七个进入ESI前1%的学科。2022年，应用心理学专业获批北京市一流本科专业。意味着中国人民大学心理学学科整体建设达到较高水平。我系现有专职教师共有24人。其中教授9人，副教授11人，讲师4人，全部教师拥有博士学位，75%的教师具有海外学习经历。"
        ]
      }
      //   nameList: [
      //     {
      //       name: "finance",
      //       textList: [
      //         "（2017年教育部学位与研究生教育发展中心公布全国第四轮学科评估结果，理论经济学和应用经济学科门类评估结果均为A+）",
      //         "2017 年教育部公布国家第四轮一级学科评估结果，中国人民大学理论经济学、应用经济学等9 个一级学科排名全国第一，其中以金融学、财政学为骨干学科的应用经济学已连续4 次排名全国之首，2017年中国人民大学成功入选一流大学建设高校名单，同时“应用经济学”和“理论经济学”成功首批入选一流学科建设名单。经济学院是国家经济学基础人才培养基地、教育部人文社会科学重点研究基地、国家“统筹支持一流大学和一流学科建设”项目、国家“2011”中国特色社会主义经济建设协同创新中心协同单位和全国中国特色社会主义政治经济学研究中心。"
      //       ]
      //     },
      //     {
      //       name: "business",
      //       textList: []
      //     }
      //   ]
    };
  },
  //不会重复执行
  updated() {
    console.log("bodyText:", this.name);
  }
};
</script>

<style lang="less" scoped>
.text_list {
  margin-bottom: 120px;
  .text_list_item {
    margin-bottom: 30px;
    font-size: 16px;
    color: #333333;
  }
}
</style>