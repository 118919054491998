<template>
  <div class="product_intro">
    <!-- <h1 class="temp">暂无内容</h1> -->
    <!-- 产品大图 -->
    <div class="big-image">
      <img src="@/assets/productIntro1.png" alt="" />
    </div>

    <!-- 以下内容为写死，暂时注释 -->
    <!-- <div class="product_list">
      <div class="product_item">
        <div class="item_pic">
          <img src="@/assets/product-temp.png" alt="" />
        </div>
        <div class="item_text">
          <p>
            产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品
          </p>
        </div>
      </div>
      <div class="product_item">
        <div class="item_pic">
          <img src="@/assets/product-temp.png" alt="" />
        </div>
        <div class="item_text">
          <p>
            产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品
          </p>
        </div>
      </div>
      <div class="product_item">
        <div class="item_pic">
          <img src="@/assets/product-temp.png" alt="" />
        </div>
        <div class="item_text">
          <p>
            产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品
          </p>
        </div>
      </div>
      <div class="product_item">
        <div class="item_pic">
          <img src="@/assets/product-temp.png" alt="" />
        </div>
        <div class="item_text">
          <p>
            产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品
          </p>
        </div>
      </div>
      <div class="product_item">
        <div class="item_pic">
          <img src="@/assets/product-temp.png" alt="" />
        </div>
        <div class="item_text">
          <p>
            产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品
          </p>
        </div>
      </div>
      <div class="product_item">
        <div class="item_pic">
          <img src="@/assets/product-temp.png" alt="" />
        </div>
        <div class="item_text">
          <p>
            产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品
          </p>
        </div>
      </div>
      <div class="product_item">
        <div class="item_pic">
          <img src="@/assets/product-temp.png" alt="" />
        </div>
        <div class="item_text">
          <p>
            产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品
          </p>
        </div>
      </div>
      <div class="product_item">
        <div class="item_pic">
          <img src="@/assets/product-temp.png" alt="" />
        </div>
        <div class="item_text">
          <p>
            产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品
          </p>
        </div>
      </div>
    </div> -->
  </div>
</template>
  
  <script>
export default {
  name: "ProductIntro",
  data() {
    return {};
  },
  mounted() {}
};
</script>
  
  <style lang="less" scoped>
.product_intro {
  min-height: 450px;
}
.temp {
  text-align: center;
  font-size: 72px;
  color: #abb2a9;
  margin: 150px auto 0;
}
.big-image {
  img {
    width: 100%;
  }
}
// 以下内容为写死，暂时注释
// @imgWidth: 306px;
// .product_intro {
//   padding: 60px;
//   background-color: #f9f9f9;
// }
// .product_list {
//   display: flex;
//   flex-wrap: wrap;
//   .product_item {
//     margin-top: 18px;
//     &:nth-child(-n + 3) {
//       margin-right: 18px;
//     }
//     &:nth-child(-n + 7):nth-child(n + 2) {
//       margin-right: 18px;
//     }
//     .item_pic {
//       img {
//         // vertical-align: center;
//         vertical-align: bottom;
//       }
//     }
//     .item_text {
//       width: @imgWidth;
//       //   height: 84px;
//       padding: 20px 15px;
//       background-color: #fff;
//       box-sizing: border-box;
//       // p {
//       //   // width: @imgWidth;
//       // }
//     }
//   }
// }
</style>