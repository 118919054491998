<template>
  <div v-if="$route.path != '/home/homebody'" class="message_box">
    <!-- 展开 -->
    <el-card v-show="isShow" class="box-card">
      <div slot="header" class="clearfix">
        <span>留言板</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          icon="el-icon-minus"
          @click="beCollapse"
        ></el-button>
      </div>
      <div class="form_box">
        <el-form ref="form" :model="form" label-width="80px">
          <el-input
            placeholder="公司名："
            v-model="form.companyName"
          ></el-input>
          <el-input placeholder="联系人：" v-model="form.contact"></el-input>

          <el-input
            placeholder="电话："
            type="tel"
            v-model="form.phoneNumber"
          ></el-input>
          <el-input
            placeholder="备注："
            type="textarea"
            v-model="form.remark"
            :autosize="{ minRows: 5 }"
          ></el-input>
          <el-button type="primary" @click="submitForm('form')">提交</el-button>
        </el-form>
      </div>
    </el-card>
    <!-- 收起 -->
    <el-card v-show="!isShow" class="box_collapse_card">
      <div slot="header" class="clearfix">
        <span>留言板</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          icon="el-icon-collection-tag"
          @click="beOpen"
        ></el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 表单数据
      form: {
        companyName: "",
        contact: "",
        phoneNumber: null,
        remark: ""
      },
      //控制messageBox是否展开，默认展开
      isShow: true
      //控制 整个组件是否显示
      //   isShowEntire: true
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    beCollapse() {
      this.isShow = false;
    },
    beOpen() {
      this.isShow = true;
    }
  }
};
</script>

<style lang="less" scoped>
.message_box {
  position: fixed;
  left: 65px;
  bottom: 60px;
  .box_collapse_card /deep/ .el-card__body,
  .el-main {
    padding: 0;
  }
  .box_collapse_card /deep/ .el-card__header {
    border: none;
  }

  .box-card {
    .form_box {
      .el-input /deep/ .el-input__inner {
        margin-bottom: 20px;
      }
      .el-textarea /deep/ .el-textarea__inner {
        margin-bottom: 20px;
      }
      .el-button {
        display: grid;
        place-items: center;
        float: right;
        width: 90px;
        height: 30px;
        border: none;
        padding: 0;
        margin-bottom: 15px;
      }
    }
  }

  /deep/ .el-card {
    border: none;
    width: 300px;
    .el-card__header {
      background-color: #007aff;
      color: #fff;
      .clearfix {
        .el-button {
          i {
            color: #fff;
            font-size: 18px;
            font-weight: 900;
          }
        }
      }
    }
    .form_box {
    }
  }
}
// 以下为临时粘贴的样式
.text {
  font-size: 14px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 480px;
}
</style>