import axios from 'axios'
import baseUrl from '@/utils/const.js'
// import { getData } from './AsyncStorage.js'

const api = axios.create({
  baseURL: baseUrl,
})

const handleError = error => {
  console.error(error)
}


export default {
  get: async (url, params) => {
    try {
      const response = await api.get(url, {
        params
      })
      return response.data
    } catch (error) {
      handleError(error)
      throw error
    }
  },
  post: async (url, data) => {
    try {
      const response = await api.post(url, data)
      return response.data
    } catch (error) {
      handleError(error)
      throw error
    }
  },
  put: async (url, data) => {
    try {
      const response = await api.put(url, data)
      return response.data
    } catch (error) {
      handleError(error)
      throw error
    }
  },
  delete: async (url) => {
    try {
      const response = await api.delete(url)
      return response.data
    } catch (error) {
      handleError(error)
      throw error
    }
  },
}