<template>
  <div class="class_body">
    <!-- 调用 text组件 -->
    <class-body-text :name="this.name"></class-body-text>
    <!-- 调用 table组件 -->
    <class-body-table ref="childTable" :name="this.name"></class-body-table>
  </div>
</template>

<script>
import ClassBodyTable from "@/components/classBody/ClassBodyTable.vue";
import ClassBodyText from "@/components/classBody/ClassBodyText.vue";
export default {
  props: {
    name: {
      type: String,
      default: "finance"
    }
  },
  components: {
    ClassBodyTable,
    ClassBodyText
  },
  data() {
    return {};
  },
  updated() {
    console.log("执行了body");
    //获取子组件table 实例
    // let child = this.$refs.childTable;
    //如果 table组件的 图片顺序没有被操作过，那么 切换到 该类目时，默认显示 第一张 table内容；否则保持 用户修改选中的内容
    // console.log("当前子组件的isChanged", child.nameObj[this.name].isChanged);
    // if (!child.nameObj[this.name].isChanged) {
    //   console.log("当前子组件的isChanged", child.nameObj[this.name].isChanged);
    //   child.activeIndex = 0;
    // }
  }
};
</script>

<style lang="less" scoped>
</style>