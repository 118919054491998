<template>
  <div class="class">
    <div class="class_list">
      <div
        v-for="(item, index) in initActiveList"
        :key="index"
        class="class_item"
      >
        <div
          class="item_pic"
          @click="goToDetail(item._id)"
        >
          <img
            v-if="item.posterPicPath"
            :src="item.posterPicPath"
            alt=""
          />
          <img
            v-else
            src="@/assets/class_default_pic.png"
            alt=""
          />
        </div>
        <div class="item_text">
          <p class="text">
            {{ item.courseDesc }}
          </p>
        </div>
      </div>
    </div>

    <!-- 以下为写死的代码，需要时放开 -->
    <!-- 标题部分 -->
    <!-- <div class="title">
      <h4>核心方向课程</h4>
    </div>
    <div class="nav_button_list">
      <div
        v-for="(item, index) in navBtnList"
        :key="index"
        class="nav_button_item"
        @click="changeMain(item.name, index)"
        :class="[activeIndex == index ? 'active' : '']"
      >
        {{ item.text }}
      </div>
    </div> -->

    <!-- <div class="com_container">
      <class-body :name="this.comName"></class-body>
    </div> -->
  </div>
</template>

<script>
import ClassBody from "@/components/ClassBody.vue";
export default {
  components: {
    ClassBody
  },
  data() {
    return {
      //用于存储 初始化请求获得的 活动列表数据
      initActiveList: []
      // 以下为写死的代码，需要时放开
      //导航栏对象
      // navBtnList: [
      //   { text: "金融经济类", name: "finance" },
      //   { text: "工商管理类", name: "business" },
      //   { text: "民商法学类", name: "law" },
      //   { text: "新闻传播类", name: "news" },
      //   { text: "哲学宗教类", name: "religion" },
      //   { text: "AI信息数理统计类", name: "statistics" },
      //   { text: "影视戏剧艺术类", name: "art" },
      //   { text: "心理学类", name: "psychology" }
      // ],
      // //动态组件名
      // comName: "finance",
      // //点击的下标
      // activeIndex: 0
    };
  },
  methods: {
    // 点击 navBtn 切换 组件
    changeMain(comname, index) {
      this.activeIndex = index;
      this.comName = comname;
    },
    initClassPage() {
      this.$http.post("applet/training/lessonList").then(res => {
        console.log(res.result);
        this.initActiveList = res.result;
        //使用的参数可能是：courseName,courseDesc,posterPicPath
      });
    },

    //跳转到详情页
    goToDetail(id) {
      //classdetail
      this.$router.push({
        path: "/home/classdetail",
        query: { dataId: id }
      });
    }
  },
  created() {
    this.initClassPage();
    // this.initDetailPage();
  }
};
</script>

<style scoped lang="less">
@imgWidth: 306px;
@imgHeight: 175px;
.class {
  padding: 60px;
  background-color: #f9f9f9;
  padding-bottom: 110px;
}
.class_list {
  display: flex;
  flex-wrap: wrap;
  .class_item {
    margin-bottom: 30px;
    &:not(:nth-child(4n)) {
      margin-right: 18px;
    }
    .item_pic {
      img {
        // vertical-align: center;
        width: @imgWidth;
        // height: 100%;
        height: @imgHeight;
        // background-size: cover;
        object-fit: cover;
        vertical-align: bottom;
      }
    }
    .item_text {
      width: @imgWidth;
      height: 84px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      background-color: #fff;
      box-sizing: border-box;
      text-align: center;
      p {
        margin: 0;
        padding: 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
// 以下为写死的内容的样式，需要时放开
// @duan: 60px;
// @hang: 40px;
// .class {
//   padding: @duan;
// }
// .title {
//   margin-bottom: @duan;
//   font-size: 28px;
// }
// //被选中的

// .nav_button_list {
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: @duan;
//   .nav_button_item {
//     padding: 0 20px;
//     min-width: 120px;
//     width: fit-content;
//     height: 42px;
//     border-radius: 8px;
//     border: 1px solid #007aff;
//     box-sizing: border-box;
//     line-height: 42px;
//     text-align: center;
//     font-size: 16px;
//     color: #007aff;
//   }
//   .active {
//     background-color: #007aff;
//     color: #fff;
//   }
// }
</style>
