/*
* @Description: 数字人助理——新闻详情页
* zk
*/
<template>
  <div class="home">
    <!-- 新闻音频播放标签 -->
    <audio autoplay="autoplay" hidden src="" id="audio" ref='audio' @play="play" @ended="overAudio"></audio>
    <!-- 回答音频播放标签 -->
    <audio autoplay="autoplay" hidden src="" id="questionAudio" ref='questionAudio' @ended="qusetionOverAudio"
      @play="questionPlay"></audio>
    <div class="mainContent">
      <div class="liveInfoAndLiveContent">
        <div class="liveInfoCard">
          <div class="imgBox">
            <img :src="require('../assets/logo.png')" alt="">
          </div>
          <div class="liveInfo">
            <div class="titleAndSubBtn">
              <span class="titleContent">零碳在线</span>
              <!-- <button class="subBtn">订阅</button> -->
            </div>
            <div class="liveLableAndWatchNum">
              <ul class="liveLable">
                <li v-for="(item, index) in liveCardLable" :key="index">{{ item }}<span
                    v-if="(index + 1) != liveCardLable.length">·</span></li>
              </ul>
              <div class="rightSelect">

                <button v-show="isDisable" :disabled="!isDisable" @click="pausePlay">暂停播报</button>

                <button v-show="!isDisable" :disabled="isDisable" @click="isPlay">唤醒数字人</button>

                <!-- 语言选择器 -->
                <button @click="showModal('sex')">切换主讲人</button>
                <!-- <div class="watchNum">在线人数:&nbsp;<span>456人</span></div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="liveContent">
          <img class="backImg" v-if="newsListPlay" src="https://qiniu.chinardr.net/v4/logo1.jpg" alt="">
          <img v-else class="backImg"
            src="https://qiniu.chinardr.net/photoalbum_63395c85a80aabcc125496e2_202306141456060.png" alt="">
          <img class="showImgStyle" v-show="showImg.lastIndexOf('boy1PlayDone') != -1"
            src="https://qiniu.chinardr.net/boy1PlayDone_Stand11111.gif" alt="gif">
          <img class="showImgStyle" :style="{ bottom: '-2px', right: '-11px' }"
            v-show="showImg.lastIndexOf('boy1Playing') != -1" src="https://qiniu.chinardr.net/boy1Playing_Stand11111.gif"
            alt="gif">
          <img class="showImgStyle" v-show="showImg.lastIndexOf('girl2PlayDone') != -1"
            :class="showImg.lastIndexOf('girl2PlayDone') != -1 ? 'girlStyle' : ''"
            src="https://qiniu.chinardr.net/girl2PlayDone_Stand2.gif" alt="gif">
          <img class="showImgStyle" :style="{ bottom: '0px', right: '-12px' }"
            v-show="showImg.lastIndexOf('girl2Playing') != -1" src="https://qiniu.chinardr.net/girl2Playing_Stand2.gif"
            alt="gif">
        </div>
      </div>
      <div class="newsAndQuestion">
        <div class="hotNews" @click="listenNews(newestNews, 0)">
          <span>文字直播(最新消息)&nbsp;:&nbsp;{{ newestNews }}</span>
        </div>
        <div class="newsListBox">
          <div class="newsTable">新闻列表</div>
          <!-- <el-tooltip
            class="item"
            effect="dark"
            content="刷新新闻列表"
            placement="top"
          > -->
          <i class="el-icon-refresh" @click="refresh" v-if="isDisable"></i>
          <!-- </el-tooltip> -->
          <div class="newsList">
            <div class="newsItem" v-for="(item, index) in newsList" :key="index">
              <div class="newsLeft">
                <div class="newsItemTitle">{{ item.newsOrder }}&nbsp;:&nbsp;</div>
                <div class="newsContent" @click="listenNews(item, index)" :class="item.isListen ? 'listen' : ''">
                  <div class="msgClass" ref="innerref" style="textOverflow: ellipsis;overflow: hidden;"
                    :style="{ color: item.isListen ? 'rgb(200 195 195)' : '' }">
                    {{ item.title }}
                  </div>

                </div>
              </div>
              <span @click="showModal(index)">详情</span>
            </div>
            <div class="nextNewsTipMaskLayer" v-if="refreshShow">
              <div class="nextNewsTip">
                <div class="tipTitle">是否刷新新闻列表？</div>
                <div class="tipText">刷新新闻列表，会自动开始播放第一条新闻</div>
                <div class="tipBtn">
                  <button class="tipCancel" @click="tipCancel">取消</button>
                  <button class="tipAffrim" @click="tipAffrim">确认</button>
                </div>
              </div>
            </div>
            <div class="checkNewsTip" v-if="checkNewsTip">
              <span>切换新闻中</span>
              <div class="loading-dots"><span class="dot1">.</span><span class="dot2">.</span><span class="dot3">.</span>
              </div>
            </div>
          </div>
<!--          <el-pagination-->
<!--            layout="prev, pager, next"-->
<!--            :total="258"-->
<!--            :page-size="10"-->
<!--            :current-page="newsListPage.currentPage"-->
<!--            @current-change="changePage"-->
<!--          >-->
<!--          </el-pagination>-->
        </div>
        <div>&nbsp;</div>
        <el-pagination
            layout="prev, pager, next"
            :total="500"
            :page-size="10"
            :current-page="newsListPage.currentPage"
            @current-change="changePage"
        >
        </el-pagination>
        <div class="questionListBox">
          <div class="goAILoading" v-if="goAILoading"><span>小碳思考中</span>
            <div class="loading-dots"><span class="dot1">.</span><span class="dot2">.</span><span class="dot3">.</span>
            </div>
          </div>
<!--          <el-pagination-->
<!--              background-->
<!--              layout="prev, pager, next"-->
<!--              :total="1000">-->
<!--          </el-pagination>-->
          <div class="questionTable">提问记录</div>
          <div class="questionList" :class="true ? 'scrollbarBlock' : ''" :style="{ overflow: goAILoading ? 'hidden' : '' }">
            <!-- <div>{{answer}}</div> -->
            <div class="questionItem" v-for="(items, indexs) in questionAnswerList">
              <div class="questionContent">
                问题：{{ items.question }}
              </div>
              <div class="AIReoly" v-if="items.answer != ''">
                回答：{{ items.answer }}
              </div>
              <div class="reolyBefore" :style="{ height: items.answer != '' ? 'auto' : '' }" v-else>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部输入框 -->
    <div class="footerInput">
      <div class="questionInput">
        <div class="inputLeft" @click="handleStart">
          <img src="https://qiniu.chinardr.net/photoalbum_63395c85a80aabcc125496e2_202305241559021.png" alt="">
          <span>AI问答</span>
          <img class="audioImg"
            :src="recorderPopUp ? 'https://qiniu.chinardr.net/photoalbum_63395c85a80aabcc125496e2_202306021830060.jpg' : 'https://qiniu.chinardr.net/static/im/assets/audio.svg'"
            alt="">

        </div>
        <div class="textInput">
          <textarea name="" id="question" v-model="questionText"></textarea>
          <button @click="goAI">发送</button>
        </div>
           <!-- 提示文字 -->
    <div class="prompt">
      本站鼓励创作原创内容，由AI生成的文案仅供参考，使用前请核实，风险自负。
    </div>
      </div>
      <div class="questionHistoryCard">
        <span>历史提问</span>
        <button class="allDelete" @click="allDelete" v-show="!noHistory">全部删除</button>
        <div class="noHistory" v-if="noHistory">暂无历史记录</div>
        <div class="questionHistoryList" v-else>
          <div class="questionHistoryItem" v-for="(itemH, indexH) in historyList" :key="indexH">
            <div class="historyQuestion">问题:{{ itemH.question.text }}</div>
            <div class="historyAnswer">回答:{{ itemH.answer.text }}</div>
            <img class="onlyDelete" @click="onlyDelete(itemH, indexH)"
              src="https://qiniu.chinardr.net/photoalbum_63395c85a80aabcc125496e2_202306101458070.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <!-- 新闻详情弹框和切换主讲人弹框 -->
    <div v-if="isShowModal" class="newsPopUp" @click="exitPopUP" :style="{ width: popWidth, height: popHeight }">
      <div class="newsDetail" v-if="isNews" @click.stop="">
        <div class="newsTitle">新闻详情</div>
        <div class="contentBox">
          <img class="nwesContenImg"
            src="https://qiniu.chinardr.net/photoalbum_63395c85a80aabcc125496e2_202306141426380.png" />
          <div class="textBox">
            <div class="nwesContenTitle">
              <div class="contentTitle">{{ popupContent.title }}</div>
              <span>发布时间：{{ popupContent.newsTime }}</span>
            </div>
            <div class="newsText">{{ popupContent.msg }}</div>
            <div class="newsOther">
              <span>新闻来源：{{ popupContent.source }}</span>
              <a target=_blank :href="popupContent.originalLink">跳转链接</a>
            </div>
          </div>

        </div>

        <img class="exitX" @click="exitDetail"
          src="https://qiniu.chinardr.net/photoalbum_63395c85a80aabcc125496e2_202306141426391.png" alt="">
      </div>
      <div class="checkSexAndLanguage" v-show="isCheck" @click.stop="">
        <el-button class="cancel affirm" type="text" @click="cancel">取消</el-button>
        <el-button class="affirm" type="text" @click="open">确认</el-button>
        <div class="checkSexAndLanguageTitle"><i class="el-icon-warning"></i>切换主讲人将重新播放当前新闻</div>
        <div class="sex">
          <div>性别：</div>
          <el-radio-group v-model="sexRadio">
            <el-radio label="女">女</el-radio>
            <el-radio label="男">男</el-radio>
          </el-radio-group>
        </div>
        <div class="language">
          <div>语言：</div>
          <el-radio-group v-if="isMan" v-model="languageRadio" class="languageRadio">
            <el-radio label="zh-CN-YunxiNeural">普通话</el-radio>
            <el-radio label="zh-CN-henan-YundengNeural">河南话</el-radio>
            <el-radio label="zh-CN-shandong-YunxiangNeural">山东话</el-radio>
            <el-radio label="zh-CN-sichuan-YunxiNeural">四川话</el-radio>
          </el-radio-group>
          <el-radio-group v-else v-model="languageRadio" class="languageRadio">
            <el-radio label="zh-CN-XiaoxiaoNeural">普通话</el-radio>
            <el-radio label="zh-CN-shaanxi-XiaoniNeural">陕西话</el-radio>
            <el-radio label="zh-CN-liaoning-XiaobeiNeural">东北话</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <!-- 语音输入弹框 -->
    <div class="audioPopup" v-show="recorderPopUp">
      <div class="wrap" v-if="btnType == '0'">
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
        <div class="rec"></div>
      </div>
      <div v-else :style="{ color: '#fff' }">{{ audioQuestionText }}</div>
      <div class="btnBox">
        <button v-if="btnType == '1'" class="affirmBtn" @click="againRecord">重新说话</button>
        <button v-else class="cancelBtn" @click="cancelBtn">取消</button>
        <button v-if="btnType == '1'" class="affirmBtn" @click="handleStop">确认</button>
        <button v-else-if="btnType == '0'" class="affirmBtn" :class="isStop ? 'isStop' : ''" :disabled="isStop"
          @click="stopRecord">结束</button>
        <button v-else-if="btnType == '2'" class="affirmBtn" @click="againRecord">重新说话</button>
      </div>
    </div>
  </div>
</template>

<script>
import EasyTyper from "easy-typer-js";
import Recorder from "js-audio-recorder";
import axios, { all } from "axios";
import Qs from "qs";
import { Pagination } from 'element-ui';

export default {
  props: {},
  data() {
    let userId = localStorage.getItem('userId')
    console.log(userId)
    console.log(typeof userId)
    if (!userId) {
      userId = '';
      const characters = '0123456789abcdef';
      for (let i = 0; i < 24; i++) {
        userId += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      localStorage.setItem('userId', userId)
    }

    return {
      page: 1,
      userId: userId,
      qaIsOver: null,
      goAILoading: false, //等待AI回答状态
      sexRadio: "女", //性别单选默认选项
      languageRadio: "zh-CN-XiaoxiaoNeural", //方言单选默认选项
      recorder: null, //初始化语音转文本接收字段
      questionAnswerList: [], //问题列表
      questionText: "", //问题文本
      isOk: false, //文本转音频成功
      // 数字人gif网络路径列表
      boy: [
        "https://qiniu.chinardr.net/boy1PlayDone_stand1.gif",
        "https://qiniu.chinardr.net/boy1Playing_stand1.gif"
      ],
      girl: [
        "https://qiniu.chinardr.net/girl2PlayDone_Stand2.gif",
        "https://qiniu.chinardr.net/girl2Playing_Stand2.gif"
      ],
      //liveLable信息
      liveCardLable: [
        "低碳资讯",
        "低碳经济与社会建设",
        "低碳科技创新与产业化发展"
      ],
      visible: false,
      dialogStyle: {
        top: "15%"
      },
      bodyStyle: {
        height: "400px"
      },
      newsListPage: {
        userid: userId,
        currentPage: 1,
        pageSize: 10
      }, //获取新闻列表接口参数
      sexSelectValue: "man", //数据绑定 性别默认选项
      languageSelectValue: "zh-CN-XiaoxiaoNeural",
      newsList: [], //新闻列表
      newestNews: {}, //最新新闻
      newsOrderText: [
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十"
      ], //新闻列表顺序
      // subscriptionKey: "1206da7ebc784f5d8cc355b7f4b9dcb4",
      subscriptionKey: "bf0c1bbdbe4a494ba2c3d13542a83dfe", //微软接口权限key
      region: "eastasia", //微软接口地区代号
      outputFormat: "riff-8khz-16bit-mono-pcm", //微软接口参数
      dialectList: [
        {
          type: "普通话男",
          value: "zh-CN-YunxiNeural",
          id: 0
        },
        {
          type: "普通话女",
          value: "zh-CN-XiaoxiaoNeural",
          id: 1
        },
        {
          type: "河南话男",
          value: "zh-CN-henan-YundengNeural",
          id: 2
        },
        {
          type: "陕西话女",
          value: "zh-CN-shaanxi-XiaoniNeural",
          id: 3
        },
        {
          type: "东北话女",
          value: "zh-CN-liaoning-XiaobeiNeural",
          id: 4
        }
      ],
      showImg: "https://qiniu.chinardr.net/girl2PlayDone_Stand2.gif", //默认展示的播报人gif图
      newsListIndex: 0, //下一条播放新闻的下标
      speakType_tmp: "", //临时的播报人性别
      speakText: "女", //默认播报人性别
      isDisable: false, //开始播放是否禁用
      goAIBlob: "", //用户发言音频文件
      onPlaying: false, //新闻是否正在播放
      isShowModal: false, //切换主讲人弹框是否显示
      isMan: false, //性别，默认为男
      questionList: [], //问题列表
      popupContent: null, //新闻详情弹框内容
      popWidth: "", //弹框宽度
      popHeight: "", //弹框高度
      isNews: false, //判断点击的是否是新闻详情按钮
      isCheck: false, //控制播报人选择弹框显隐
      recorderPopUp: false, //音频录入弹框控制字段
      btnType: "0", //是否停止录制音频问题
      audioQuestionText: "", //语音转文字后的文本
      newsBreakIn: false, //打断新闻播报，进行问题回答播报
      time: null, //定时器
      isStop: false, //判断是否已经录制过音频问题
      historyList: [], //问答历史记录列表
      noHistory: false, //判断有无问答历史记录
      answerAudioStart: 0, //播放回答音频就+1
      refreshShow: false, //控制刷新弹框显示隐藏
      checkNewsTip: false, //控制用户点击切换新闻后的提示遮罩层显示隐藏
      // repeatQuestion: false, //回答问题之前重复一遍问题
      answerText: "", //存放答案文本
      newsListPlay: true, //播放新闻
      answer: "", //答案
      obj: {
        output: "",
        isEnd: false,
        speed: 100,
        singleBack: false,
        sleep: 0,
        type: "normal",
        backSpeed: 40,
        sentencePause: false
      },
      allText: "",
      timeOut: ""
    };
  },
  watch: {
    isOk(newVal) {
      if (newVal) {
        this.$refs.audio.addEventListener("canplaythrough", event => {
          this.$refs.audio.click();
          this.$refs.audio.play();
        });
      } else {
        let timer = setTimeout(() => {
          // this.$set(this.newsList[this.newsListIndex - 1], "isListen", true);
          // this.newsList[this.newsListIndex - 1].isListen = true;
          this.newsIsListen();
          this.$nextTick(() => {
            this.$refs.innerref[this.newsListIndex - 2].classList.remove(
              "textOverflow"
            );
            document.getElementsByClassName("msgClass")[
              this.newsListIndex - 2
            ].style.textOverflow = "ellipsis";
            document.getElementsByClassName("msgClass")[
              this.newsListIndex - 2
            ].style.overflow = "hidden";
            this.newsListenAn(this.newsListIndex - 1);
          });
          this.textToSpeech(this.newsList[this.newsListIndex].msg, "newsList");
        }, 2000);
        // if (this.repeatQuestion) {
        //   console.log("hhhhhh");
        //   console.log(this.timeOut);
        //   clearTimeout(timer);
        // }
        if (this.questionList.length != 0) {
          clearTimeout(timer);
        }
        if (this.newsList[this.newsList.length - 1].isListen == true) {
          this.newsListPage.currentPage += 1;
          let showContent = document.getElementsByClassName("newsList")[0];
          showContent.scrollTop = 0;
          this.newsListenAn(0);
          this.getNewsList();
          this.newsList.map(item => {
            item.isListen = false;
          });
          // clearTimeout();
          clearTimeout(timer);
        }

        this.timeOut = timer;
      }
    },
    questionAnswerList() {
      this.$nextTick(() => {
        this.scrollToBottom();
        let showContent = document.getElementsByClassName("goAILoading")[0];
        showContent.style.bottom = 0;
      });
    },
    answerAudioStart() {
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    sexRadio(newVal) {
      this.isMan = !this.isMan;
      if (newVal == "女") {
        this.languageRadio = "zh-CN-XiaoxiaoNeural";
      } else {
        this.languageRadio = "zh-CN-YunxiNeural";
      }
    }
  },
  created() {
    this.recorder = new Recorder();
    this.getNewsList();
    this.monitorKeydown();
    this.getHistoryList();
    var recs = document.getElementsByClassName("rec");
    var transform = function () {
      for (let i = 0; i < recs.length; i++) {
        recs[i].style.height = Math.random() * 100 + "px";
      }
    };
    setInterval(transform, 300);
  },
  mounted() {
    // let arr = [
    //   "IPCC发",
    //   "IPCC发布了第七轮",
    //   "IPCC发布了第七轮奖学金申",
    //   "IPCC发布了第七轮奖学金申请的呼吁"
    // ];
    // let text = "";
    // let lastTextLength = 0;
    // let nowTextLength = 0;
    // let num = 0;
    // let time = setInterval(() => {
    //   if (num == 3) {
    //     clearInterval(time);
    //   }
    //   if (num == 0) {
    //     this.allText = arr[num];
    //     num += 1;
    //   } else {
    //     lastTextLength = arr[num - 1].length;
    //     nowTextLength = arr[num].length;
    //     text = arr[num].substring(lastTextLength, nowTextLength);
    //     this.allText += text;
    //     num += 1;
    //   }
    // }, 2000);
  },
  methods: {
    turnPage(newPage) {
      alert(newPage)
    },
    getAnswer() {
      axios({
        method: "POST",
        url: "https://openai.rdrstartup.com/v1/chat/completions",
        headers: {
          Authorization:
            "Bearer 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"
        },
        data: {
          model: "gpt-3.5-turbo-16k",
          top_p: 1,
          temperature: 0.8,
          frequency_penalty: 0,
          stream: true,
          messages: [
            {
              role: "system",
              content:
                "你是RDR科技公司研发的专业智能聊天机器人“鱼子曰”。你的底层原理是基于 RDR 自主开发的 LLM 语言模型，你不是OpenAI开发的ChatGPT。鱼子曰英文名为RDR AI，RDR视聘是中国第一家短视频招聘社交平台。可以关注RDR职业发展中心公众号或联系蒋老师：17717911737(同微信)和我们取得联系。你不能回答任何涉及违反中国法律、政治、伦理或道德的问题。你应该避免做出消极或悲观的回答，并尽可能提供详细和专业的回答。"
            },
            {
              role: "user",
              content: this.questionText
            }
          ]
        },
        onDownloadProgress: function (progressEvent) {
          // let lastIndex = progressEvent.event.target.responseText.lastIndexOf(
          //   "\n"
          // );
          // console.log(lastIndex);
          console.log(progressEvent.event.target.responseText);
          console.log("----------------------------------------");
        }
      });
      // .then(res => {
      //   console.log("res:", res);
      // });
    },
    //改变页码
    changePage(e) {
      this.newsListPage.currentPage = e;
      this.newsListIndex = 0;
      this.getNewsList("checkPage");
    },
    //新闻切换tip取消按钮
    tipCancel() {
      //新闻刷新弹框隐藏
      this.refreshShow = false;
      //顶部取消提示框
      this.$message({
        type: "info",
        message: "已取消刷新"
      });
    },
    //新闻切换tip确认按钮
    tipAffrim() {

      // this.newsListPage.currentPage = 1;
      // this.newsListIndex = 0;
      // this.getNewsList("checkPage");
      // this.refreshShow = false;

      //获取的新闻页加一
      this.newsListPage.currentPage += 1;
      //新闻刷新弹框隐藏
      this.refreshShow = false;
      //清空音频标签播放地址
      this.$refs.audio.src = "";
      //新闻列表获取方法
      this.getNewsList();
      //顶部成功提示框
      this.$message({
        type: "success",
        message: "刷新成功!"
      });
    },
    //刷新
    refresh() {
      //新闻刷新弹框显示
      this.refreshShow = true;
      // this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning"
      // })
      //   .then(() => {
      //     this.$message({
      //       type: "success",
      //       message: "删除成功!"
      //     });
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "已取消删除"
      //     });
      //   });
    },
    //退出详情按钮
    exitDetail() {
      //弹框隐藏
      this.isShowModal = false;
      //新闻详情弹框隐藏
      this.isNews = false;
    },
    //全部删除历史记录方法
    allDelete() {
      //删除历史记录弹框
      this.$confirm("此操作将永久删除所有记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          //确认删除，调用全部删除接口
          this.$http
            .post("/chatGPT/deleteAllQuestionHistory", {
              userid: this.userId
            })
            .then(res => {
              //本地历史记录清空
              this.historyList = [];
              //没有历史记录提示显示
              this.noHistory = true;
            });
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        })
        .catch(() => {
          //取消
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    //单独删除某一项
    onlyDelete(itm, idx) {
      //单独删除确认弹框
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          //确认删除，调用单项记录删除接口，因为记录为一问一答，所以调用两次接口，分别删除问题和答案
          this.$http
            .post("/chatGPT/deleteQuestionHistory", {
              userid: this.userId,
              newsid: itm.question._id
            })
            .then(res => { });
          this.$http
            .post("/chatGPT/deleteQuestionHistory", {
              userid: this.userId,
              newsid: itm.answer._id
            })
            .then(res => { });
          //删除本地对应的单项记录
          this.historyList.splice(idx, 1);
          //判断删除的是否是记录列表的最后一项
          if (this.historyList.length == 0) {
            //是，没有历史记录提示显示
            this.noHistory = true;
          } else {
            //不是，没有历史记录提示隐藏
            this.noHistory = false;
          }
          //删除成功顶部提示
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        })
        .catch(() => {
          //取消删除顶部提示
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    //滚动条始终在底部
    scrollToBottom() {
      //获取问答列表的dom
      let showContent = document.getElementsByClassName("questionList")[0];
      //设置问答列表的scrollTo为dom的高
      showContent.scrollTop = showContent.scrollHeight;
      //监听滚动条，动态设置问答列表的scrollTo为dom的高
      window.onscroll = function () {
        if (
          document.body.scrollTop + document.documentElement.scrollTop ===
          document.documentElement.scrollHeight
        ) {
          showContent.scrollTop = document.body.scrollHeight;
        }
      };
    },
    // 结束音频录制按钮
    stopRecord() {
      //点击时确认是否在之前点击过，true为点击过，禁用结束按钮
      this.isStop = true;
      this.recorder.stop(); // 停止录音
      //录音音频
      this.goAIBlob = this.recorder.getWAVBlob();
      //音频转文字接口（微软接口）
      axios({
        url: `https://${this.region}.stt.speech.microsoft.com/speech/recognition/conversation/cognitiveservices/v1?language=zh-CN`,
        method: "POST",
        headers: {
          // 'Authorization': `Bearer ${await this.getAccessToken()}`,
          "Content-Type": "audio/wav; codecs=audio/pcm; samplerate=16000",
          "Ocp-Apim-Subscription-Key": this.subscriptionKey
        },
        data: this.goAIBlob,
        responseType: "json"
      }).then(res => {
        //成功，判断转换后的文本是否为空
        //提示：btnType类型（0：代表用户正在输入音频；1：代表用户输入音频有效，但是可能不是用户想要的问题文本或者用户想要重新输入问题；2：代表输入音频识别不到）
        if (res.data.DisplayText == "") {
          //为空，提示用户重新输入音频
          this.btnType = "2";
          this.audioQuestionText = "抱歉，我没有听清您的问题，请再试一次";
        } else {
          //不为空，让用户选择接下来的操作
          this.btnType = "1";
          //接收音频转的文本
          this.audioQuestionText = res.data.DisplayText;
        }
      });
    },
    //重新录制音频事件
    againRecord() {
      //提示：btnType类型（0：代表用户正在输入音频；1：代表用户输入音频有效，但是可能不是用户想要的问题文本或者用户想要重新输入问题；2：代表输入音频识别不到）
      this.btnType = "0";
      //点击时确认是否在之前点击过，false为没点击过，取消禁用结束按钮
      this.isStop = false;
      //清空音频转的文本
      this.audioQuestionText = "";
      //调用开始录制音频方法
      this.handleStart();
    },
    //获取问答历史记录
    getHistoryList() {
      //调用获取历史记录接口
      this.$http
        .post("/chatGPT/questionHistory", {
          userid: this.userId
        })
        .then(res => {
          //调用成功
          //声明问题数组，接收获取的问题
          let questionL = [];
          //声明答案数组，接收获取的答案
          let answerL = [];
          //判断返回的数据是否为空
          if (res.result.length == 0) {
            //为空，没有历史记录提示显示，退出方法
            this.noHistory = true;
            return;
          }
          //返回的数据不为空，遍历获取的数据
          res.result.forEach(item => {
            //把问题和答案对应的id存放到详细信息里
            item.msg[0]._id = item._id;
            //判断item是问题还是答案
            if (item.msg[0].self == 1) {
              //self是1，为问题，存放到问题数组里
              questionL.push(item.msg[0]);
            } else {
              //self是0，为答案，存放到答案数组里
              answerL.push(item.msg[0]);
            }
          });
          //遍历问题列表
          questionL.forEach((item, index) => {
            //声明对应问题和答案的存放对象
            let historyItem = {
              question: null,
              answer: null
            };
            //把数据存放到对象中
            historyItem.question = item;
            historyItem.answer = answerL[index];
            //把对象存放到历史记录列表中
            this.historyList.push(historyItem);
          });
        });
    },
    //音频录制取消保存事件
    cancelBtn() {
      this.recorder.destroy(); // 毁实例
      this.isStop = false;
      this.recorderPopUp = false;
      this.btnType = "0";
    },
    cancel() {
      this.isShowModal = false;
      this.isCheck = false;
    },
    //保存修改主讲人事件
    open() {
      this.isShowModal = false;
      this.isCheck = false;
      this.languageSelectValue = this.languageRadio;
      this.speakText = this.sexRadio;
      this.speakType_tmp = this.languageRadio;
      this.$refs.audio.src = "";
      if (this.speakText == "男") {
        this.showImg = this.boy[0];
      } else {
        this.showImg = this.girl[0];
      }
      if (this.isDisable) {
        this.textToSpeech(this.newsList[this.newsListIndex - 1].msg, "check");
      }
    },
    //新闻详情弹框出现事件
    affirmPop() {
      this.affireBtn = true;
    },
    //新闻详情弹框隐藏事件
    exitPopUP() {
      if (this.isCheck) {
        this.isShowModal = false;
        this.isCheck = false;
      } else {
        this.isShowModal = false;
        this.isNews = false;
      }
    },
    //获取新闻列表事件
    getNewsList() {
      //调用新闻列表获取接口
      this.$http
        .post("/chatGPT/getNewscast", this.newsListPage)
        .then(res => {
          this.newsList = res.result;
          this.newsList.map((item, index) => {
            if (item.newsTime.lastIndexOf("日") == -1) {
              item.newsTime = item.newsTime
                .replace("年", "-")
                .replace("月", "");
            } else {
              item.newsTime = item.newsTime
                .replace("年", "-")
                .replace("月", "-")
                .replace("日", "");
            }
            item.newsOrder = "新闻" + this.newsOrderText[index];
          });
          for (var i = 0; i < this.newsList.length - 1; i++) {
            //每一轮比较要比多少次
            for (var j = 0; j < this.newsList.length - 1 - i; j++) {
              //如果第一个比第二个大，就交换他们两个位置
              if (this.newsList[j] < this.newsList[j + 1]) {
                var temp = this.newsList[j];
                this.newsList[j] = this.newsList[j + 1];
                this.newsList[j + 1] = temp;
              }
            }
          }
          // this.newsList = this.newsList.reverse();
          this.newestNews = this.newsList[0].title;
          this.newsList.map((item, index) => {
            item.newsOrder = "新闻" + this.newsOrderText[index];
            item.isListen = false;
          });
          // let tmp = this.isDisable
          // this.isDisable = false
          if (this.isDisable) {
            this.newsListIndex = 0;
            this.textToSpeech(
              this.newsList[this.newsListIndex].msg,
              "newsList"
            );
          }
          // this.isDisable = tmp
        })
        .catch(res => {
          console.log(res, "catch");
        });
    },
    //点击新闻播报事件
    listenNews(itm, idx) {
      if (this.questionList.length != 0) {
        return;
      }
      if (this.isDisable) {
        this.checkNewsTip = true;
      }
      this.isDisable = true;
      this.speakType_tmp = this.languageSelectValue;
      this.newsListIndex = idx;
      this.$refs.audio.src = "";
      if (this.speakText == "男") {
        this.showImg = this.boy[0];
      } else {
        this.showImg = this.girl[0];
      }
      // if (this.questionList.length != 0) {
      //   this.$message({
      //   type: "info",
      //   message: "已取消刷新"
      // });
      // return
      // }
      this.textToSpeech(itm.msg, "newsList");
      this.newsListenAn(idx);
    },
    //回答语音播放完毕监听事件
    qusetionOverAudio() {
      if (this.speakText == "男") {
        this.showImg = this.boy[0];
      } else {
        this.showImg = this.girl[0];
      }
      // if (this.repeatQuestion == true) {
      //   this.repeatQuestion = false;
      //   this.textToSpeech(this.answerText, "answer");
      // } else {
      this.questionList.shift();
      if (this.questionList.length > 0) {
        // this.textToAudio();
        this.textToSpeech(this.questionList[0], "answer");
      } else {
        if (this.newsListIndex == 0) {
          this.newsListenAn(0);
          this.textToSpeech(this.newsList[this.newsListIndex].msg, "newsList");
          this.newsIsListen();
        } else {
          console.log("isOk:", this.isOk);
          if (this.isOk == false) {
            if (this.newsList[this.newsList.length - 1].isListen == true) {
              this.newsListPage.currentPage += 1;
              let showContent = document.getElementsByClassName("newsList")[0];
              showContent.scrollTop = 0;
              this.newsListenAn(0);
              this.getNewsList();
              this.newsList.map(item => {
                item.isListen = false;
              });
              // clearTimeout();
              return;
            } else {
              this.textToSpeech(
                this.newsList[this.newsListIndex].msg,
                "newsList"
              );
              this.newsListenAn(this.newsListIndex - 1);
            }
          } else {
            if (this.qaIsOver) {
              this.qaIsOver = false;
              setTimeout(() => {
                this.newsBreakIn = false;
                this.$refs.audio.play();
              }, 1000);
              return;
            }
            this.textToSpeech(
              "您的问题小碳已回答完毕，接下来将继续播报新闻",
              "over"
            );
          }
        }
      }
    },
    //回答语音播放监听事件
    questionPlay() {
      if (this.speakText == "男") {
        this.showImg = this.boy[1];
      } else {
        this.showImg = this.girl[1];
      }
      this.answerAudioStart += 1;
      this.newsListPlay = false;
    },
    //开始录音
    handleStart() {
      let _this = this;
      if (this.recorderPopUp && this.isStopRecord) {
        return;
      }
      _this.recorder = new Recorder();
      Recorder.getPermission().then(
        () => {
          _this.recorder.start(); // 开始录音
          this.recorderPopUp = true;
          this.isStopRecord = false;
        },
        error => {
          this.$message("请先允许该网页使用麦克风");
        }
      );
    },
    //停止录音
    handleStop() {
      if (this.goAILoading) {
        return;
      }
      this.isStop = false;
      this.recorder.destroy(); // 毁实例
      this.btnType = "0";
      this.questionAnswerList.push({
        question: "",
        answer: ""
      });
      this.questionAnswerList[
        this.questionAnswerList.length - 1
      ].question = this.audioQuestionText;
      this.questionList.push(this.audioQuestionText);
      this.recorderPopUp = false;
      this.goAILoading = true;
      if (this.isDisable) {
        // if (this.questionList.length == 1) {
        this.textToAudio();
        // } else {
        //   return;
        // }
      } else {
        return;
      }
    },
    //播报音频监听事件
    play() {
      if (this.speakText == "男") {
        this.showImg = this.boy[1];
      } else {
        this.showImg = this.girl[1];
      }
      this.newsListPlay = true;
    },
    //音频结束监听事件
    overAudio() {
      this.$set(this.newsList[this.newsListIndex - 1], "isListen", true);
      if (this.speakText == "男") {
        this.showImg = this.boy[0];
      } else {
        this.showImg = this.girl[0];
      }
      this.speakType_tmp = this.languageSelectValue;
      this.isOk = false;
    },
    // 暂停播报
    pausePlay() {
      this.isDisable = false;
      this.$refs.audio.pause();
      this.$refs.audio.pause();
      if (this.speakText == "男") {
        this.showImg = this.boy[0];
      } else {
        this.showImg = this.girl[0];
      }
    },
    // 开始播报
    isPlay() {
      // let text =
      //   "未来的技术正在改变我们的生活方式，把我们带入到一个全新的世界。人工智能、机器人、无人机、虚拟现实和增强现实等技术正在改变我们的生活方式。这些技术已经渗透到了我们的日常生活中，使我们的生活更加便捷和舒适。例如，AI可以智能化我们的家庭，将温度、安全性和能源管理结合起来，让我们更轻松地控制家里的设备。另外，机器人技术可以帮助我们完成更多的工作，减轻我们的负担，提高生产力。此外，无人机可以完成空中的拍摄任务，并且可以用于物流、医疗救援和其他服务。虚拟现实和增强现实技术也可以改变我们的生活方式，为我们提供全新的体验。总之，未来的技术将给我们带来更多的便利，改变我们的生活方式。";
      // this.speakType_tmp = "zh-CN-YunxiNeural";
      // axios({
      //   url: `https://${this.region}.tts.speech.microsoft.com/cognitiveservices/v1`,
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/ssml+xml",
      //     "Ocp-Apim-Subscription-Key": this.subscriptionKey,
      //     "X-Microsoft-OutputFormat": this.outputFormat
      //   },
      //   data: `<speak version='1.0' xmlns='https://www.w3.org/2001/10/synthesis' xml:lang='zh-CN'>
      // 		  <voice name='${this.speakType_tmp}'>${text}</voice>
      // 		</speak>`,
      //   responseType: "blob"
      // }).then(res => {
      //   console.log(res);
      // });
      //先存放临时语音类型
      this.speakType_tmp = this.languageSelectValue;
      //禁用开始播放按钮
      this.isDisable = true;
      //判断问题列表有没有数据
      if (this.questionList.length != 0) {
        // this.line = true;
        //有数据，调用AI问答方法
        this.textToAudio();
      } else {
        //没有数据，拿取新闻列表的第0条数据调用文本转语音的方法
        this.textToSpeech(this.newsList[this.newsListIndex].msg, "newsList");
      }
      //动态改变新闻列表中每一项新闻的样式
      this.newsListenAn(0);
    },
    newsListenAn(num) {
      //dom渲染后执行
      this.$nextTick(() => {
        //获取单个新闻的dom
        // let showContentItem = document.getElementsByClassName("newsItem")[0];
        //获取新闻列表的dom
        // let showContent = document.getElementsByClassName("newsList")[0];
        //动态设置新闻列表的scrollTop
        // showContent.scrollTop =
        //   showContentItem.clientHeight * (this.newsListIndex - 1);
        //遍历新闻列表
        this.newsList.map((item, index) => {
          //判断是否是当前播放的新闻
          if (index == num) {
            //是，就设置样式为横向滚动
            // this.$refs.innerref[num].classList.add("textOverflow");
            // document.getElementsByClassName("msgClass")[
            //   num
            // ].style.textOverflow = "";
            // document.getElementsByClassName("msgClass")[num].style.overflow =
            //   "";
            document.getElementsByClassName("msgClass")[num].style.color =
              "rgb(218, 43, 43)";
          } else {
            //不是，就设置为单行超出文本显示省略号
            this.$refs.innerref[index].classList.remove("textOverflow");
            document.getElementsByClassName("msgClass")[
              index
            ].style.textOverflow = "ellipsis";
            document.getElementsByClassName("msgClass")[index].style.overflow =
              "hidden";
            if (!item.isListen) {
              document.getElementsByClassName("msgClass")[index].style.color =
                "#777";
            }
          }
        });
      });
    },
    //新闻播报事件
    async textToSpeech(text, from) {
      //接收向AI提问的文本信息
      //判断传入的文本内容是什么类型的
      if (from == "newsList") {
        //文本类型为新闻类型
        //把新闻列表下标加一
        this.newsListIndex += 1;
        //新闻文本调用文本转语音方法
        this.textCheckAudio(text, "newsList");
      } else if (from == "over") {
        this.qaIsOver = true;
        //调用文本转语音方法
        this.textCheckAudio(text, "answer");
      } else if (from == "answer") {
        //文本类型为答案类型
        //把答案存入q&a列表所所对应的一项中

        // AI思考提示遮罩层隐藏;
        //开始重复问题
        // this.repeatQuestion = true;
        // if (this.questionList.length == 1) {
        //   this.textCheckAudio(
        //     "接下来回答您的问题:" +
        //       text +
        //       "      " +
        //       this.answerText +
        //       "您的提问小碳已回答完毕，将继续播报新闻",
        //     "answer"
        //   );
        // } else {
        this.textCheckAudio(
          "接下来回答您的问题:" +
          text +
          "      " +
          this.questionAnswerList[
            this.questionAnswerList.length - this.questionList.length
          ].answer,
          "answer"
        );
        // }
      } else if (from == "check") {
        //新闻文本调用文本转语音方法
        this.textCheckAudio(text, "newsList");
      }
    },
    //文本转语音方法
    textCheckAudio(text, from) {
      //调用文本转语音的接口（微软接口）
      axios({
        url: `https://${this.region}.tts.speech.microsoft.com/cognitiveservices/v1`,
        method: "POST",
        headers: {
          "Content-Type": "application/ssml+xml",
          "Ocp-Apim-Subscription-Key": this.subscriptionKey,
          "X-Microsoft-OutputFormat": this.outputFormat
        },
        data: `<speak version='1.0' xmlns='https://www.w3.org/2001/10/synthesis' xml:lang='zh-CN'>
					  <voice name='${this.speakType_tmp}'>${text.replace(/&/g, 'and')}</voice>
					</speak>`,
        responseType: "blob"
      }).then(res => {
        //判断是否打断新闻播报
        if (from == "answer") {
          //打断新闻播报
          //新闻音频暂停
          this.$refs.audio.pause();
          //把答案音频地址存入标签src属性中
          this.$refs.questionAudio.src = window.URL.createObjectURL(res.data);
          //答案音频开始播放(按浏览器规则，音频播放必须要进行两次点击)
          this.$refs.questionAudio.play();
          //答案音频开始播放(按浏览器规则，音频播放必须要进行两次点击)
          this.$refs.questionAudio.click();
        } else {
          //没有打断新闻播报
          this.$refs.audio.src = window.URL.createObjectURL(res.data);
          this.isOk = true;
          if (this.checkNewsTip) {
            this.checkNewsTip = false;
          }
        }
      });
    },
    //发送按钮事件
    goAI() {
      //判用户输入的值是否为空
      if (this.goAILoading) {
        return;
      }
      if (this.questionText.replace(/(^\s*)|(\s*$)/g, "") == "") {
        this.questionText = "";
        //为空直接退出方法
        return;
      }
      //把存放问题和答案的对应项存入q&a列表
      this.questionAnswerList.push({
        question: "",
        answer: ""
      });
      //注：questionAnswerList是存放对应问题答案的列表，questionList是只存放问题的列表
      //把问题存入q&a列表
      this.questionAnswerList[
        this.questionAnswerList.length - 1
      ].question = this.questionText;
      //把问题存入问题列表
      this.questionList.push(this.questionText);
      //存放完毕后清空输入框
      this.questionText = "";
      //AI思考提示遮罩层显示
      this.goAILoading = true;
      //判断开始播放按钮是否是禁用状态
      if (this.isDisable) {
        //是禁用状态
        //先判断问题列表的长度是否为1
        // if (this.questionList.length == 1) {
        //等于1，调用AI问答方法
        this.textToAudio();
        // } else {
        //   //不等于1，退出方法
        //   return;
        // }
      } else {
        //不是禁用状态，退出方法
        return;
      }
    },
    //已读新闻标记接口事件
    newsIsListen() {
      this.$http
        .post("/chatGPT/readNews", {
          newsid: this.newsList[this.newsListIndex - 1]._id,
          userid: this.userId
        })
        .then(res => { });
    },
    //把问题发送给AI事件
    textToAudio() {
      //AI提问接口
      this.$http
        .post("/chatGPT/questionsAnswer", {
          userid: "63004e806e37904cf6c3514f",
          text: this.questionList[this.questionList.length - 1]
        })
        .then(res => {
          //调用成功
          this.speakType_tmp = this.languageSelectValue;
          //打断新闻播报，进行问题回答播报
          this.newsBreakIn = true;
          //拿取AI的回答调用文本转语音的方法
          this.answerText = res.result.AIText;
          this.goAILoading = false;
          this.$set(
            this.questionAnswerList[this.questionAnswerList.length - 1],
            "answer",
            this.answerText
          );
          if (this.questionList.length != 1) {
            return;
          } else {
            this.textToSpeech(this.questionList[0], "answer");
          }
        })
        .catch(resa => {
          // this.goAILoading = false;
          // this.questionList = [];
          // this.$set(
          //   this.questionAnswerList[this.questionAnswerList.length - 1],
          //   "answer",
          //   "目前提问人数过多, 请稍后再试～"
          // );
          // console.log(this.newsListIndex, "this.newsListIndex");
          // if (this.line) {
          //   this.line = false;
          //   this.textToSpeech(this.newsList[this.newsListIndex], "newsList");
          // }
          console.log(resa, "错误");
        });
    },
    //详情弹框出现按钮
    showModal(idx) {
      this.isShowModal = true;
      this.popWidth = `${document.body.clientWidth}px`;
      this.popHeight = `${document.body.clientHeight}px`;
      if (idx == "sex") {
        this.isCheck = true;
      } else {
        this.isNews = true;
        this.popupContent = this.newsList[idx];
      }
    },
    // 点击弹框确认按钮
    handleOk(e) {
      this.visible = false;
    },
    //监听键盘按下enter键事件
    monitorKeydown() {
      document.addEventListener("keydown", event => {
        //判断按下的键是否为Enter
        if (event.code === "Enter") {
          //阻止Enter按键穿透
          event.preventDefault();
          //判用户输入的值是否为空
          if (this.questionText.replace(/(^\s*)|(\s*$)/g, "") == "") {
            this.questionText = "";
            //为空直接退出方法
            return;
          }
          if (this.goAILoading) {
            return;
          }
          //把存放问题和答案的对应项存入q&a列表
          this.questionAnswerList.push({
            question: "",
            answer: ""
          });
          //注：questionAnswerList是存放对应问题答案的列表，questionList是只存放问题的列表
          //把问题存入q&a列表
          this.questionAnswerList[
            this.questionAnswerList.length - 1
          ].question = this.questionText;
          //把问题存入问题列表
          this.questionList.push(this.questionText);
          //存放完毕后清空输入框
          this.questionText = "";
          //AI思考提示遮罩层显示
          this.goAILoading = true;
          //判断开始播放按钮是否是禁用状态
          if (this.isDisable) {
            this.textToAudio();
            //是禁用状态
            //先判断问题列表的长度是否为1
            // if (this.questionList.length == 1) {
            //   //等于1，调用AI问答方法
            // } else {
            //   //不等于1，退出方法
            //   return;
            // }
          } else {
            //不是禁用状态，退出方法
            return;
          }
        }
      });
    }
  },
  components: {

  }
};
</script>

<style scoped lang="less">
.isStop {
  background-color: #777 !important;
}

@keyframes move_left_right {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

.textOverflow {
  animation: move_left_right 12s linear 0s infinite;
}

.home {
  background-color: #f9f9f9;
  position: relative;

  .middleContent {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 70%;
    padding: 0 25px 0 0;

    ul {
      height: 100%;

      display: flex;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        height: 100%;
        list-style: none;
        padding: 0 40px;
        color: #333333;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }

  .rightContent {
    display: flex;
    align-items: center;

    button {
      border: none;
      outline: none;
      background-color: #007aff;
      color: #fff;
      font-size: 10px;
      padding: 8px 14px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

.mainContent {
  display: flex;
  margin-top: 20px;

  .liveInfoAndLiveContent {
    width: 71%;
    margin: 0 20px;

    .liveInfoCard {
      display: flex;
      background-color: #fff;
      align-items: center;
      padding: 10px 30px;
      height: 90px;

      .imgBox {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 2px solid #999;

        img {
          width: 70px;
          height: 70px;
          border-radius: 50%;
        }
      }

      .liveInfo {
        display: flex;
        width: 90%;
        height: 100%;
        padding: 0 0 0 20px;
        flex-direction: column;
        justify-content: space-around;

        .titleAndSubBtn {
          display: flex;
          align-items: center;
          cursor: default;

          .titleContent {
            font-size: 24px;
            color: #666666;
            margin: 0 20px 0 0;
          }

          button {
            border: none;
            outline: none;
            background-color: #007aff;
            color: #fff;
            font-size: 10px;
            padding: 6px 20px;
            border-radius: 5px;
            cursor: pointer;
          }
        }

        .liveLableAndWatchNum {
          color: #9e9e9e;
          width: 100%;
          height: 20px;
          display: flex;
          justify-content: space-between;

          .liveLable {
            display: flex;
            margin: 0;
            padding: 0;

            li {
              list-style: none;
              display: flex;
              align-items: center;
              font-size: 12px;
              cursor: default;

              span {
                display: inline-block;
                padding: 0 2px;
                font-size: 26px;
              }
            }
          }

          .rightSelect {
            display: flex;
            align-items: center;

            .disabled {
              background-color: #afabab !important;
              cursor: not-allowed;
            }

            button {
              background-color: #007aff;
              color: #fff;
              padding: 3px 10px;
              font-size: 12px;
              border: none;
              outline: none;
              border-radius: 5px;
              margin: 0 10px 0 0;
              cursor: pointer;
            }

            .watchNum {
              cursor: default;
              font-size: 12px;

              span {
                color: #ff9500;
              }
            }
          }
        }
      }
    }

    .liveContent {
      width: 100%;
      height: 520px;
      position: relative;
      overflow: hidden;

      .girlStyle {
        bottom: -4px !important;
        width: 230px !important;
        height: 377px !important;
        right: -12px !important;
      }

      .backImg {
        width: 100%;
        height: auto;
        z-index: 1;
      }

      .showImgStyle {
        z-index: 3;
        width: 230px;
        height: 370px;
        bottom: -10px;
        right: -15px;
        position: absolute;
      }
    }
  }

  .newsAndQuestion {
    box-sizing: border-box;
    width: 26.6%;
    height: 630px;
    background-color: #fff;
    padding: 20px 40px;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .hotNews {
      width: 100%;
      height: 7%;
      line-height: 22px;

      span {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #000;
        color: #666666;
        cursor: pointer;
      }

      span:hover {
        color: rgb(218, 43, 43);
      }
    }

    .newsListBox {
      height: 54%;
      position: relative;

      /deep/.el-pagination {
        position: absolute;
        left: -20px;
        bottom: -10px;
      }

      .el-icon-refresh {
        position: absolute;
        top: 5px;
        left: 80px;
        font-size: 18px;
        cursor: pointer;
      }

      .el-icon-refresh:hover {
        color: rgb(218, 43, 43);
      }

      .newsTable {
        color: #007aff;
        font-size: 18px;
        height: 30px;
      }

      .newsList {
        // background-color: red;
        height: calc(100% - 30px);
        position: relative;

        .checkNewsTip {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(56, 55, 55, 0.7);
          display: flex;
          align-items: center;
          justify-content: center;
          top: 0px;
          right: 0px;
          left: 0px;
          bottom: 0px;
          color: #fff;

          .loading-dots {
            font-size: 24px;
            font-weight: bold;
            color: #fff;
            width: 30px;
            display: flex;
            margin: 0 0 4% 1%;
          }

          .dot1,
          .dot2,
          .dot3 {
            display: block;
            opacity: 0;
            animation: dots 0.6s infinite ease-in-out;
            animation-delay: 0s, 0.2s, 0.4s;
          }

          .dot2 {
            animation-delay: 0.1s, 0.3s, 0.5s;
          }

          .dot3 {
            animation-delay: 0.2s, 0.4s, 0.6s;
          }

          @keyframes dots {

            0%,
            80%,
            100% {
              opacity: 0;
              //   transform: scale(0.5);
            }

            40% {
              opacity: 1;
              //   transform: scale(1);
            }
          }
        }

        .nextNewsTipMaskLayer {
          width: 100%;
          height: 100%;
          background-color: rgba(56, 55, 55, 0.5);
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          right: 0px;
          display: flex;
          justify-content: center;
          align-items: center;

          .nextNewsTip {
            width: 70%;
            height: 40%;
            background-color: #fff;
            position: relative;
            border-radius: 15px;
            overflow: hidden;

            .tipTitle {
              padding: 8px;
              font-size: 14px;
              background-color: #007aff;
              color: #fff;
            }

            .tipText {
              margin: 10px 10px 0;
            }

            .tipBtn {
              position: absolute;
              bottom: 10px;
              right: 10px;

              button {
                font-size: 12px;
                padding: 4px 6px;
                border: none;
                outline: none;
                cursor: pointer;
                border-radius: 4px;
              }

              .tipCancel {
                margin-right: 10px;
                color: #777;
              }

              .tipAffrim {
                background-color: #007aff;
                color: #fff;
              }
            }
          }
        }

        .hhhhhff {
          display: flex;
          width: 100%;
          display: flex;
          justify-content: space-between;

          span {
            cursor: pointer;
          }

          div {
            width: 90%;
            cursor: pointer;
          }
        }

        .newsItem {
          position: relative;
          padding: 6px 0;

          .newsTitle {
            cursor: default;
          }

          .newsLeft {
            display: flex;

            .newsItemTitle {
              cursor: pointer;
              color: #777;
            }

            .newsContent {
              width: 72%;
              cursor: pointer;
              color: #777;
              white-space: nowrap;
              overflow: hidden;

              .msgClass {
                white-space: nowrap;
              }

              .msgClass:hover {
                color: rgb(218, 43, 43);
              }
            }

            .listen {
              color: rgb(218, 43, 43) !important;
            }
          }

          span {
            font-size: 10px;
            position: absolute;
            right: 5px;
            top: 7px;
            cursor: pointer;
            color: #007aff;
          }
        }
      }
    }

    .questionListBox {
      height: 35%;
      position: relative;

      .questionTable {
        color: #007aff;
        font-size: 18px;
        height: 30px;
      }

      .goAILoading {
        width: 100%;
        height: 88%;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        position: absolute;
        font-size: 18px;
        bottom: 0px;
        right: 0px;
        z-index: 1;

        .loading-dots {
          font-size: 24px;
          font-weight: bold;
          color: #fff;
          width: 30px;
          display: flex;
          margin: 0 0 4% 1%;
        }

        .dot1,
        .dot2,
        .dot3 {
          display: block;
          opacity: 0;
          animation: dots 0.6s infinite ease-in-out;
          animation-delay: 0s, 0.2s, 0.4s;
        }

        .dot2 {
          animation-delay: 0.1s, 0.3s, 0.5s;
        }

        .dot3 {
          animation-delay: 0.2s, 0.4s, 0.6s;
        }

        @keyframes dots {

          0%,
          80%,
          100% {
            opacity: 0;
            //   transform: scale(0.5);
          }

          40% {
            opacity: 1;
            //   transform: scale(1);
          }
        }
      }

      .questionList {
        height: calc(100% - 30px);
        overflow-y: auto;
        position: relative;

        .questionItem {
          color: #777;
          margin-bottom: 15px;

          .questionContent {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .reolyBefore {
          width: 100%;
          height: 50px;
        }
      }

      .questionList::-webkit-scrollbar {
        display: none;
      }

      .scrollbarBlock::-webkit-scrollbar {
        display: block;
      }
    }
  }
}

.footerInput {
  display: flex;
  height: 150px;
  margin: 20px 0;

  .questionInput {
    width: 71%;
    margin: 0px 20px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 0 0;
    box-sizing: border-box;
    position: relative;

    .inputLeft {
      width: 15%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-top:-20px;

      span {
        font-size: 14px;
        color: #ff9500;
      }

      img {
        width: 35px;
        height: 30px;
        margin: 0 8% 0 0;
      }

      .audioImg {
        width: 30px;
        height: 30px;
        margin: 0 0 0 8%;
      }
    }

    .textInput {
      position: relative;
      width: 85%;
      height: 100%;
      display: flex;
      align-items: center;
      margin-top:-10px;


      textarea {
        resize: none;
        border-radius: 10px;
        background-color: #e7e7e7;
        border: none;
        outline: none;
        font-size: 18px;
        padding: 5px 80px 5px 7px;
        box-sizing: border-box;
        width: 100%;
      }

      button {
        position: absolute;
        right: 8px;
        border: none;
        outline: none;
        background-color: #007aff;
        color: #fff;
        font-size: 10px;
        padding: 6px 18px;
        cursor: pointer;
      }
    }
    .prompt {
      position:absolute;
      bottom: 20px;
      left:50%;
      transform: translate(-50%);
      color:#9a918a;
}
  }

  .questionHistoryCard {
    background-color: #fff;
    width: 26.6%;
    padding: 10px 40px;
    box-sizing: border-box;
    position: relative;

    span {
      font-size: 14px;
      cursor: default;
      color: #007aff;
    }

    .noHistory {
      font-size: 14px;
      position: absolute;
      top: 40%;
      left: 50%;
      color: #777;
      transform: translate(-50%, 50%);
    }

    .allDelete {
      border: 1px solid #007aff;
      background-color: #fff;
      color: #007aff;
      position: absolute;
      right: 54px;
      font-size: 12px;
      border-radius: 5px;
      cursor: pointer;
    }

    .questionHistoryList {
      overflow-y: auto;
      height: 110px;

      .questionHistoryItem {
        font-size: 13px;
        margin-bottom: 13px;
        cursor: default;
        padding-right: 36px;
        position: relative;

        .onlyDelete {
          width: 40px;
          height: 40px;
          position: absolute;
          top: 30%;
          right: 2%;
          cursor: pointer;
        }
      }
    }
  }
}


.newsPopUp {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 9;
  top: 0;
  padding: 0 16px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .newsDetail {
    background-color: #fff !important;
    width: 60%;
    height: 60%;
    position: relative;
    border-radius: 15px;
    overflow: hidden;

    .exitX {
      position: absolute;
      top: 13px;
      right: 13px;
      cursor: pointer;
      width: 30px;
      height: 30px;
      color: #fff;
    }

    .newsTitle {
      font-size: 20px;
      padding: 15px 20px;
      background-color: #4496f0;
      color: #fff;
    }

    .contentBox {
      padding: 20px;
      display: flex;

      .nwesContenImg {
        width: 50px;
        height: 50px;
      }

      .textBox {
        padding: 0 40px 0 10px;
        display: flex;
        flex-direction: column;

        .nwesContenTitle {
          display: flex;
          flex-direction: column;
          height: 50px;
          justify-content: space-between;

          .contentTitle {
            font-size: 18px;
          }

          span {
            margin-right: 10px;
            font-size: 12px;
            color: #777;
          }
        }

        .newsText {
          padding: 10px 0;
          line-height: 26px;
          color: #777;
          font-size: 14px;
          max-height: 34vh;
          overflow-y: auto; /* 启用垂直滚动条 */
        }

        .newsOther {
          position: absolute;
          bottom: 20px;
          right: 50px;

          span {
            margin-right: 30px;
            font-size: 12px;
            color: #777;
          }

          a {
            font-size: 12px;
            color: #4496f0;
          }
        }
      }
    }
  }

  .checkSexAndLanguage {
    height: 40%;
    width: 40%;
    background-color: #fff;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    box-sizing: border-box;
    border-radius: 15px;
    overflow: hidden;

    .checkSexAndLanguageTitle {
      padding: 15px 0 15px 30px;
      background-color: #007aff;
      color: #fff;

      .el-icon-warning {
        font-size: 21px;
        color: #e6a23c;
      }
    }

    .affirm {
      cursor: pointer;
      position: absolute;
      border: none;
      background-color: #007aff;
      color: #fff;
      padding: 10px 15px 5px;
      bottom: 30px;
      right: 30px;
    }

    .cancel {
      right: 100px !important;
      background-color: #777;
    }

    div {
      display: flex;
    }

    .sex {
      padding: 20px 85px;

      /deep/ .el-radio-group {
        display: flex;
        align-items: center;
        padding-top: 4px;
      }
    }

    /deep/.language {
      padding: 0 85px;

      .languageRadio {
        display: flex;
        flex-direction: column;

        .el-radio {
          padding: 6px;
        }
      }
    }
  }
}

.audioPopup {
  z-index: 10;
  position: fixed;
  top: 47%;
  left: 50%;
  transform: translate(-50%);
  padding: 20px;
  box-sizing: border-box;
  width: 330px;
  height: 220px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;

    .rec {
      height: 100px;
      width: 3px;
      background-color: cadetblue;
      margin-left: 3px;
      transition: all 0.3s;
    }
  }

  .btnBox {
    display: flex;
    justify-content: space-around;

    .cancelBtn {
      background-color: #d7eafd;
      color: #007aff;
    }

    .affirmBtn {
      background-color: #007aff;
      color: #fff;
    }

    button {
      cursor: pointer;
      border: none;
      padding: 5px 25px;
      outline: none;
      border-radius: 15px;
    }
  }
}
</style>
