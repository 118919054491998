import Vue from 'vue'

import App from './App.vue'

import ElementUI from 'element-ui';
import router from './router'
import 'element-ui/lib/theme-chalk/index.css';
import $http from '@/utils/axios.js';

//h5项目的组件引入
import VueRouter from 'vue-router'
import SubProjectApp from './SubProjectApp.vue'
import CentSearch from '@/components/h5/CentSearch.vue'
import SearchResult from '@/components/h5/SearchResult.vue'
//全局挂载 $http
Vue.prototype.$http = $http;
Vue.config.productionTip = false
Vue.use(ElementUI)
router.beforeEach((to, from, next) => {
  //切换页面滚动到页面顶部
  window.scrollTo(0, 0);
  next();
})
Vue.directive('title', {
  //设置网站title
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')




console.log("执行main.js中的if条件前的视图宽度：", window.innerWidth);
// if (window.innerWidth < 769) {
//   Vue.use(VueRouter); //Vue中使用router插件
//   // 创建一个新的 Vue 路由实例
//   const mbrouter = new VueRouter({
//     routes: [
//       // 子项目的路由配置
//       {
//         path: '/',
//         redirect: '/centSearch'
//       },
//       {
//         path: '/centSearch',
//         component: CentSearch
//       },
//       {
//         path: '/searchResult',
//         component: SearchResult
//       }
//     ]
//   })
//   //h5的vue实例
//   new Vue({
//     mbrouter,
//     render: h => h(SubProjectApp)
//   }).$mount('#app2')
// } else {

// }