import Vue from 'vue'
import VueRouter from 'vue-router'
// import {
//   createWebHistory
// } from 'vue-router'
import Home from '@/components/Home.vue'
import LoGin from '@/components/Login.vue'
import AboutUS from '@/components/AboutUs.vue'
import ProductIntro from '@/components/ProductIntro.vue'
import HomeBody from '@/components/HomeBody.vue'
import Activity from '@/components/Activity.vue'
import Class from '@/components/Class.vue'
import ClassBody from '@/components/ClassBody.vue'
import ClassBodyText from '@/components/classBody/ClassBodyText.vue'
import ClassBodyTable from '@/components/classBody/ClassBodyTable.vue'
import DigitalHuman from '@/components/DigitalHuman.vue'
import ClassDetail from '@/components/ClassDetail.vue'
import ActivityDetail from '@/components/ActivityDetail.vue'
import CentSearch from '@/components/h5/CentSearch.vue'
import SearchResult from '@/components/h5/SearchResult.vue'

Vue.use(VueRouter) //Vue中使用router插件

const routes = [ //路由配置                  ，配置路由路径与组件的对应关系
  {
    path: '/h5',
    redirect: '/h5/centSearch'
  },
  { //h5默认页面
    path: '/h5/centSearch',
    component: CentSearch,
    meta: {
      title: '证书查询'
    },
  },
  {
    path: '/h5/searchResult',
    component: SearchResult,
    meta: {
      title: '证书查询结果'
    }
  },
  {
    path: '/',
    redirect: '/home',
    meta: {
      title: '主页'
    }
  },
  {
    path: '/home',
    // redirect: '/home/homebody',
    redirect: '/home/DigitalHuman',
    component: Home,
    children: [{
        path: 'aboutus',
        component: AboutUS
      },
      {
        path: 'classdetail',
        component: ClassDetail
      },
      {
        path: 'productintro',
        component: ProductIntro
      },
      {
        path: 'login',
        component: LoGin
      },
      {
        path: 'homebody',
        component: HomeBody,
        meta: {
          title: '主页'
        }
      },
      {
        path: 'activity',
        component: Activity
      },
      {
        path: 'DigitalHuman',
        component: DigitalHuman
      },
      {
        path: 'activitydetail',
        component: ActivityDetail
      },
      {
        path: 'class',
        component: Class,
        children: [{
            path: 'classbodytext',
            component: ClassBodyText
          },
          {
            path: 'classbodytable',
            component: ClassBodyTable
          }
        ]
      }
    ]
  }
]



const router = new VueRouter({ //新建路由实例
  // mode: 'history',
  // history: createWebHistory(),
  routes
})
//设置标题代码
router.afterEach((to, from) => {
  // document.title = to.meta.title;
  document.title = "零碳在线";
});
export default router //导出路由实例，在main.js中导入使用
