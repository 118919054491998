<template>
  <div class="container">
    <div class="nav">
      <img src="@/assets/h5/logo_h5.png" alt="" />
      <h2>零碳在线</h2>
    </div>
    <div class="header">
      <h1>上海市低碳科技与产业发展协会</h1>
      <h1>会员证书查询结果</h1>
    </div>
    <div v-if="this.dataList" class="body">
      <div v-if="currentTab == 'person'" class="main">
        <div class="main_item">
          <div class="key"><span>姓</span><span>名：</span></div>
          <span>{{ dataList.name }}</span>
        </div>
        <div class="main_item">
          <div class="key">
            <span>会员类型：</span>
          </div>
          <span>个人会员证书</span>
        </div>
        <div class="main_item">
          <div class="key">
            <span>证书编号：</span>
          </div>
          <span>{{ dataList.memberNo }}</span>
        </div>
        <div class="main_item">
          <div class="key">
            <span>起止时间：</span>
          </div>
          <span>{{ dataList.duringtime }}</span>
          <!-- <span>2023年6月19日-2024年6月19日</span> -->
        </div>
      </div>
      <div v-if="currentTab == 'company'" class="main">
        <div class="main_item">
          <div class="key"><span>公司名称：</span></div>
          <span>{{dataList.name}}</span>
        </div>
        <div class="main_item">
          <div class="key">
            <span>会员类型：</span>
          </div>
          <span>单位会员证书</span>
        </div>
        <div class="main_item">
          <div class="key">
            <span>证书编号：</span>
          </div>
          <span>{{dataList.memberNo}}</span>
        </div>
        <div class="main_item">
          <div class="key">
            <span>起止时间：</span>
          </div>
          <!-- <span>2023年6月19日-2024年6月19日</span> -->
          <span>{{dataList.duringtime}}</span>
        </div>
      </div>

      <div @click="close()" class="close_btn">
        关闭
      </div>
    </div>
    <div v-else class="no_body">
      <div v-if="false" class="main_tips">
        <p>暂无结果</p>
      </div>
      <div class="main_tips">
        <p>您输入的信息有误或您的</p>
        <p>会员信息未更新/未注册</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.currentTab = this.$route.query.currentTab;
    console.log("查询结果页的currentTab:", this.currentTab);
    this.dataList = this.$route.query.dataList;
    console.log("查询结果页收到的dataList:", this.dataList);
  },
  data() {
    return {
      currentTab: "", //获取 证书查询页返回的结果
      dataList: {} //获取 查询页面 返回的 数据。
    };
  },
  methods: {
    //格式化日期-> xx年xx月xx日
    formatDate(date1) {
      // console.log("目前日期:",date1);
      let date = new Date(date1);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dates = date.getDate();
      // var arr = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
      // var day = date.getDay();
      return year + "年" + month + "月" + dates + "日";
    },
    // 关闭按钮事件
    close() {
      this.$router.go(-1);
    }
  }
};
</script>
<style scoped lang="less">
@padding_hor: 20px;
@padding_ver: 15px;
@body_radius: 8px;
.container {
  text-align: center;
  //   padding: 20px;
}
.nav {
  padding: 10px 20px 20px;
  display: flex;
  align-items: center;
  img {
    display: block;
    width: 3rem;
    height: 2rem;
    margin-right: 20px;
    // vertical-align: middle;
  }
  h2 {
    display: block;
    font-size: 16px;
    // vertical-align: middle;
  }
}
.header {
  padding: 10px 45px;
  background-color: #eaf4ff;
  h1 {
    font-size: 18px;
  }
}
.body {
  padding: @padding_ver @padding_hor;
  .main {
    .main_item {
      display: flex;
      margin-bottom: 20px;
      &:nth-child(1) {
        margin-top: 30px;
      }
      &:last-child {
        margin-bottom: 50px;
      }
      .key {
        display: flex;
        justify-content: space-between;
        width: 85px;
        margin-right: 10px;
      }
    }
  }
  .close_btn {
    margin-top: 30px;
    width: 100%;
    height: 40px;
    background-color: #007aff;
    border-radius: @body_radius;

    line-height: 40px;
    color: #fff;
  }
}
.no_body {
  display: flex;
  justify-content: center;
  align-items: center;

  .main_tips {
    margin-top: 150px;
  }
}
</style>